/* global BigInt, */
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";
import { Address, keccak256, toHex } from "viem";

import {
    getFeedKickedOutSubscriptionFromBackend,
    readFeedSubscriptions,
} from "src/services/api";
import {
    getSubscriptionFromContract,
    getSubscriptionsFromContract,
} from "src/services/murmur";
import { addProfileData } from "src/slices/profileSlice";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import { AppDispatch, RootState } from "src/store";
import { KickedOutSubscription } from "src/types/api-interfaces";
import {
    ArrayElement,
    EnhancedGetFeedSubscribersResponse,
} from "src/types/extraTypes";
import { MurmurContractType } from "src/types/murmurContractType";

type LoadingTypes = "currentUserSubscriptions" | "feedSubscriptions";
type ContractSubscription = {
    subscriber: Address;
    burningRate: number;
    topicId: number;
};
type SubscriptionFromBackend = ObjectToCamel<
    ArrayElement<EnhancedGetFeedSubscribersResponse["subscriptions"]>
>;

interface ISubscriptionsState {
    currentUserSubscriptionsByTopicId: Record<
        string,
        ContractSubscription | null
    >;
    feedSubscriptionsByTopic: Record<
        string,
        {
            totalNumberOfSubcriptions: number;
            subscriptions: Omit<SubscriptionFromBackend, "profile">[];
        }
    >;
    currentUserPreviousKickedOutSubscriptionByTopicId: Record<
        string,
        ObjectToCamel<KickedOutSubscription> | null
    >;
    loading: Record<LoadingTypes | string, boolean>;
}

export const subscriptionsSlice = createSlice({
    name: "subscriptions",
    initialState: {
        currentUserSubscriptionsByTopicId: {},
        feedSubscriptionsByTopic: {},
        currentUserPreviousKickedOutSubscriptionByTopicId: {},
        loading: {
            currentUserSubscriptions: false,
            feedSubscriptions: false,
        },
    } as ISubscriptionsState,
    reducers: {
        setLoading: (
            state,
            {
                payload: { type, status },
            }: PayloadAction<{ type: LoadingTypes | string; status: boolean }>,
        ) => {
            state.loading[type] = status;
        },
        setCurrentUserSubscription: (
            state,
            {
                payload: { topicId, subscription },
            }: PayloadAction<{
                topicId: number;
                subscription: ContractSubscription | null;
            }>,
        ) => {
            state.currentUserSubscriptionsByTopicId[topicId] = subscription;
        },
        setCurrentUserSubscriptions: (
            state,
            { payload }: PayloadAction<ContractSubscription[]>,
        ) => {
            payload.forEach((subscription) => {
                state.currentUserSubscriptionsByTopicId[subscription.topicId] =
                    subscription;
            });
        },
        setCurrentUserPreviousKickedOutSubscription(
            state,
            {
                payload: { topicId, subscription },
            }: PayloadAction<{
                topicId: number;
                subscription: ObjectToCamel<KickedOutSubscription> | null;
            }>,
        ) {
            state.currentUserPreviousKickedOutSubscriptionByTopicId[topicId] =
                subscription;
        },
        setFeedSubscriptions: (
            state,
            {
                payload: { topicId, subscriptions, totalNumberOfSubcriptions },
            }: PayloadAction<{
                topicId: number;
                subscriptions: Omit<SubscriptionFromBackend, "profile">[];
                totalNumberOfSubcriptions: number;
            }>,
        ) => {
            state.feedSubscriptionsByTopic[topicId] = {
                subscriptions,
                totalNumberOfSubcriptions,
            };
        },
    },
});

export const {
    setLoading,
    setCurrentUserSubscriptions,
    setCurrentUserSubscription,
    setCurrentUserPreviousKickedOutSubscription,
    setFeedSubscriptions,
} = subscriptionsSlice.actions;

export const getCurrentUserSubscriptions =
    (murmurContract: MurmurContractType) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentUserWalletAddress } = getState().auth;
        if (!currentUserWalletAddress) {
            console.error("No current user wallet address to fetch");
            return;
        }

        dispatch(
            setLoading({ type: "currentUserSubscriptions", status: true }),
        );
        try {
            const subs = await getSubscriptionsFromContract(
                murmurContract,
                currentUserWalletAddress,
            );

            dispatch(setCurrentUserSubscriptions(subs));

            return subs;
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: `${e}`,
                }),
            );
            throw e;
        } finally {
            dispatch(
                setLoading({ type: "currentUserSubscriptions", status: false }),
            );
        }
    };

export const getCurrentUserSubscription =
    (murmurContract: MurmurContractType, topicId: number) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentUserWalletAddress } = getState().auth;
        if (!currentUserWalletAddress) {
            console.error("No current user wallet address to fetch");
            return;
        }

        dispatch(
            setLoading({ type: `currentUserSubTo${topicId}`, status: true }),
        );

        try {
            const sub = await getSubscriptionFromContract(
                murmurContract,
                topicId,
                currentUserWalletAddress,
            );

            if (sub) {
                dispatch(
                    setCurrentUserSubscription({
                        topicId,
                        subscription: sub,
                    }),
                );

                return sub;
            } else {
                dispatch(
                    setCurrentUserSubscription({ topicId, subscription: null }),
                );
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(
                setLoading({
                    type: `currentUserSubTo${topicId}`,
                    status: false,
                }),
            );
        }
    };

export const getCurrentUserPreviousKickedOutSubscriptionFromBackend =
    (topicId: number) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const { currentUserWalletAddress } = getState().auth;
            if (currentUserWalletAddress) {
                const sub = await getFeedKickedOutSubscriptionFromBackend(
                    topicId,
                    currentUserWalletAddress,
                );
                if (sub) {
                    dispatch(
                        setCurrentUserPreviousKickedOutSubscription({
                            topicId,
                            subscription: sub,
                        }),
                    );
                } else {
                    dispatch(
                        setCurrentUserPreviousKickedOutSubscription({
                            topicId,
                            subscription: null,
                        }),
                    );
                }
            }
        } catch (e) {
            console.error(e);
            dispatch(
                setCurrentUserPreviousKickedOutSubscription({
                    topicId,
                    subscription: null,
                }),
            );
        }
    };

export const getFeedSubscriptions =
    (topicId: number) => async (dispatch: AppDispatch) => {
        dispatch(setLoading({ type: "feedSubscriptions", status: true }));
        try {
            const { subscriptions, total } =
                await readFeedSubscriptions(topicId);
            subscriptions.forEach(({ profile }) =>
                dispatch(
                    addProfileData({
                        profile,
                        walletAddress: profile.walletAddress,
                    }),
                ),
            );

            dispatch(
                setFeedSubscriptions({
                    topicId: topicId,
                    subscriptions: subscriptions.map(
                        ({ profile: _profile, ...rest }) => rest,
                    ),
                    totalNumberOfSubcriptions: total,
                }),
            );

            return subscriptions;
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading({ type: "feedSubscriptions", status: false }));
        }
    };

export function selectLoading(type: LoadingTypes | string) {
    return (state: RootState) => {
        return state.subscriptions.loading[type];
    };
}
// User subscriptions
function selectCurrentUserSubscriptions(state: RootState) {
    return state.subscriptions.currentUserSubscriptionsByTopicId;
}

export function selectCurrentUserSubscriptionTo(topicId: number) {
    return createSelector(
        selectCurrentUserSubscriptions,
        (currentUserSubscriptions) => {
            const subscription = currentUserSubscriptions[topicId];

            if (subscription) {
                return {
                    ...subscription,
                    burningRate: BigInt(subscription.burningRate),
                    hash: keccak256(toHex(JSON.stringify(subscription))),
                };
            }

            return subscription; //can be undefined if not fetched or null if fetched and not existent
        },
    );
}

export const selectCurrentUserSubscriptionsList = createSelector(
    selectCurrentUserSubscriptions,
    (subs) => Object.values(subs).filter((sub) => sub !== null),
);

export function selectCurrentUserPreviousKickedOutSubscription(
    topicId: number,
) {
    return function selectCurrentUserPreviousSubscriptionFromState(
        state: RootState,
    ) {
        return state.subscriptions
            .currentUserPreviousKickedOutSubscriptionByTopicId[topicId];
    };
}

// Feed subscriptions
function selectFeedSubscriptionsObject(topicId: number) {
    return function selectFeedSubscriptionsFromState(state: RootState) {
        return {
            ...(state.subscriptions.feedSubscriptionsByTopic[topicId] || {}),
            subscriptions: state.subscriptions.feedSubscriptionsByTopic[
                topicId
            ]?.subscriptions.map((data) => ({
                ...data,
                profile: state.profiles?.profileByAddress[data.subscriber],
            })),
        };
    };
}
export const selectNumberOfFeedSubscriptions = (topicId: number) =>
    createSelector(
        selectFeedSubscriptionsObject(topicId),
        (subsObject) => subsObject?.totalNumberOfSubcriptions,
    );
export const selectFeedSubscriptions = (
    topicId: number,
    excludeAddress: string | null = null,
) =>
    createSelector(selectFeedSubscriptionsObject(topicId), (subsObject) => {
        const subscriptions = subsObject?.subscriptions;
        if (!subscriptions) {
            return [];
        }
        const parsedSubs = subscriptions.map((subscription) => ({
            ...subscription,
            burningRate: BigInt(subscription.burningRate),
        }));

        if (excludeAddress) {
            return parsedSubs.filter(
                ({ subscriber }) =>
                    subscriber.toLowerCase() !== excludeAddress.toLowerCase(),
            );
        }

        return parsedSubs;
    });

export const selectSortedFeedSubscriptions = (topicId: number) =>
    createSelector(selectFeedSubscriptions(topicId), (subscriptions) => {
        let sortedSubscriptions: Array<
            Omit<SubscriptionFromBackend, "burningRate"> & {
                burningRate: bigint;
            }
        > = [];

        if (subscriptions.length > 0) {
            sortedSubscriptions = [...subscriptions].sort((a, b) => {
                return Number(b.burningRate) - Number(a.burningRate);
            });
        }

        return sortedSubscriptions;
    });

export default subscriptionsSlice.reducer;
