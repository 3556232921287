/* global BigInt, */
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import {
    getBurningRateStepFromContract,
    getCooldownPeriodFromContract,
    getPaymentDelayDuration,
    getPermilFeeFromContract,
    getSubscriptionFeeFromContract,
} from "src/services/murmur";

import { AppDispatch, RootState } from "src/store";
import { MurmurContractType } from "src/types/murmurContractType";

interface IContractSettingsState {
    loaded: boolean;
    paymentDelayDuration: number;
    cooldownPeriod: number;
    protocolFeePermil: number;
    subscriptionFee: number;
    burningRateStep: number;
}

export const contractSettingsSlice = createSlice({
    name: "contractSettings",
    initialState: {
        loaded: false,
        paymentDelayDuration: 0,
        cooldownPeriod: 0,
        protocolFeePermil: 0,
        subscriptionFee: 0,
        burningRateStep: 0,
    } as IContractSettingsState,
    reducers: {
        setContractSettings: (
            state,
            { payload }: PayloadAction<IContractSettingsState>,
        ) => {
            Object.keys(payload).forEach((key) => {
                const k = key as keyof IContractSettingsState;
                state[k] = payload[k] as never;
            });
        },
    },
});

export const { setContractSettings } = contractSettingsSlice.actions;

export const getContractSettings =
    (murmurContract: MurmurContractType) => async (dispatch: AppDispatch) => {
        const paymentDelayDuration =
            await getPaymentDelayDuration(murmurContract);
        const cooldownPeriod =
            await getCooldownPeriodFromContract(murmurContract);
        const protocolFeePermil =
            await getPermilFeeFromContract(murmurContract);
        const subscriptionFee =
            await getSubscriptionFeeFromContract(murmurContract);
        const burningRateStep =
            await getBurningRateStepFromContract(murmurContract);

        dispatch(
            setContractSettings({
                loaded: true,
                paymentDelayDuration: Number(paymentDelayDuration),
                cooldownPeriod: Number(cooldownPeriod),
                protocolFeePermil: Number(protocolFeePermil),
                subscriptionFee: Number(subscriptionFee),
                burningRateStep: Number(burningRateStep),
            }),
        );
    };

function selectContractSettingsState(state: RootState) {
    return state.contractSettings;
}
type ContractSetting = {
    paymentDelayDuration: bigint | null;
    cooldownPeriod: bigint | null;
    protocolFeePermil: bigint | null;
    subscriptionFee: bigint | null;
    burningRateStep: bigint | null;
};
export const selectContractSettings = createSelector(
    selectContractSettingsState,
    function mapSettingsToBigInt(settings) {
        return Object.keys(settings).reduce(
            (acc: ContractSetting, key) => {
                const settingValue =
                    settings[key as keyof IContractSettingsState];
                acc[key as keyof ContractSetting] = !Number.isNaN(settingValue)
                    ? BigInt(settingValue)
                    : null;

                return acc;
            },
            {
                paymentDelayDuration: null,
                cooldownPeriod: null,
                protocolFeePermil: null,
                subscriptionFee: null,
                burningRateStep: null,
            },
        );
    },
);

export default contractSettingsSlice.reducer;
