import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectIsConnected } from "src/slices/authSlice";

import CongratsRevealMessageModal from "src/components/CongratsRevealMessageModal";
import CongratsSubscriptionModal from "src/components/CongratsSubscriptionModal";
import Header from "src/components/Header";
import LotteryWinnerModal from "src/components/LotteryWinnerModal";
import NavBar from "src/components/NavBar";
import SubscriberKickedOutModal from "src/components/SubscriberKickedOutModal";
import TooLateModal from "src/components/TooLateModal";

import { LAYOUT, ROUTES } from "src/constants";

export default function PageContainer({
    showBackground = false,
    showNav = true,
    showHeader = false,
    children,
    sx = {},
    ...props
}) {
    const theme = useTheme();
    const isConnected = useSelector(selectIsConnected);

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const location = useLocation();
    const isOnboarding = location.pathname === ROUTES.START;
    const isIOS =
        isMobile &&
        typeof navigator !== "undefined" &&
        /iPad|iPhone|iPod/.test(navigator.userAgent);

    const responsiveStyle = {
        marginLeft: isTablet
            ? `calc((100vw - ${LAYOUT.NAVBAR.WIDTH}px) / 2))`
            : "0px",
        maxWidth:
            !isMobile && !isOnboarding
                ? `${LAYOUT.MAIN_CONTENT.MAX_WIDTH}px`
                : "100%",

        ...(showBackground && {
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 100%), url("/img/background.svg")`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
        }),
    };

    const containerHeight =
        isIOS && !showBackground
            ? `calc(100vh - env(safe-area-inset-bottom))`
            : "100vh";

    if (!isConnected) {
        showNav = false;
    }
    return (
        <>
            <Grid
                container
                sx={{
                    height: containerHeight,
                    position: "relative",
                    overflow: "auto",
                }}
                {...props}
            >
                {!isMobile && showNav && (
                    <Grid
                        item
                        sx={{
                            width: isTablet ? LAYOUT.NAVBAR.WIDTH : 0,
                        }}
                    >
                        <NavBar isIOS={isIOS} />
                    </Grid>
                )}
                <Grid item xs>
                    <Box
                        sx={{
                            ...responsiveStyle,
                            height: "100%",
                            position: "relative",
                            margin: "auto",
                            ...sx,
                        }}
                    >
                        <Stack
                            sx={{
                                height: "100%",
                                maxHeight: "100vh",
                            }}
                        >
                            {showHeader && <Header isMobile={isMobile} />}
                            <Box
                                flex={1}
                                sx={{
                                    overflow: "auto",
                                }}
                            >
                                {children}
                            </Box>
                            {isMobile && showNav && <NavBar isIOS={isIOS} />}
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            {isConnected && (
                <>
                    <TooLateModal />
                    <CongratsRevealMessageModal />
                    <CongratsSubscriptionModal />
                    <SubscriberKickedOutModal />
                    <LotteryWinnerModal />
                </>
            )}
        </>
    );
}
