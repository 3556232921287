import axios, { AxiosError } from "axios";
import { differenceInMilliseconds } from "date-fns";
import { jwtDecode } from "jwt-decode";
import { objectToCamel, objectToSnake } from "ts-case-convert";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";
import { Address, Hex } from "viem";

import { logoutFromAll } from "src/slices/authSlice";

import {
    CURRENCY_SYMBOL,
    PUBLIC_API_URLS,
    TAKE_MESSAGES_AMOUNT,
    TAKE_SUBSCRIBERS_AMOUNT,
} from "src/constants";
import store from "src/store";
import {
    ChatMessage,
    GetAllPushNotificationSubscriptionResponse,
    GetChatMessagesResponse,
    GetCurrentUserPointsResponse,
    GetFeedSubscribersRequestArgs,
    GetPublicKeyResponse,
    GetPushNotificationSubscriptionResponse,
    GetSkipTakeRequestArgs,
    GetSubscriberAddressesToBeKickedOutResponse,
    GetTopicsResponse,
    KickedOutSubscription,
    LotteryEvent,
    LotteryEventWinner,
    MessageComputedFields,
    NotificationType,
    PostAuthRequestArgs,
    PostChatMessageRequestArgs,
    PostMessageDataRequestArgs,
    PostMessageDataResponse,
    PostPublicKeyRequestArgs,
    PostReferralRequestArgs,
    PostTopicsRequestArgs,
    PostTopicsResponse,
    PostVoteRequestArgs,
    ProfileData,
    PutLotteryEventWinnerArgs,
    RevenueData,
    SuccessResponse,
    TopicsQueryArgs,
} from "src/types/api-interfaces";
import {
    EnhancedGetFeedSubscribersResponse,
    EnhancedGetMessagesResponse,
    EnhancedGetProfileResponse,
    EnhancedMessageData,
    EnhancedPostAuthResponse,
    EnhancedProfileData,
    ParsedTLPData,
} from "src/types/extraTypes";
import { matchesRoute, sleep, waitFor } from "src/utils";
import { getPrivyAccessTokenOrLogout } from "src/utils/getPrivyAccessTokenOrLogout";

axios.defaults.baseURL = `${import.meta.env.VITE_BACKEND_HOST}/api`;
axios.defaults.withCredentials = true;

let isRefreshing = false;
// see https://axios-http.com/docs/interceptors
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        // Handle session expiration
        if (error.response && error.response.status === 401) {
            if (error.config.url === "/auth/") {
                // This case can happen when no more privy token in cookie but privy context is able to refresh it if we reload the page
                window.location.reload();

                console.error(error);
                return Promise.reject(error);
            }
            if (!isRefreshing) {
                try {
                    isRefreshing = true;
                    // we get a fresh token
                    // in case the token is no more in cookie, privy will give us the last token
                    // but then we will hit a 401 on /auth call because no more token in cookie, this will reload the page and put again the token in cookie
                    const newAccessToken = await getPrivyAccessTokenOrLogout(
                        `API 401 error on ${error.config.url}`,
                        true,
                    );
                    if (newAccessToken) {
                        await auth();
                        const originalRequest = error.config;
                        return await axios.request(originalRequest);
                    }
                    isRefreshing = false;
                } catch (e) {
                    isRefreshing = false;
                    console.error(
                        `Error attempting to authenticate with privy token again, logging out...`,
                        e,
                    );
                    store.dispatch(logoutFromAll());
                    throw error;
                }
            } else {
                // Wait for re-auth to be done
                const startOfWait = new Date();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore -- needed the time to put the utils.js file in typescript
                await waitFor(1_000, () => {
                    if (new Date().getTime() - startOfWait.getTime() > 10_000) {
                        throw new Error(
                            "Wait timeout - authenticating with privy token again took too long",
                        );
                    }

                    return !isRefreshing;
                });
                const originalRequest = error.config;
                return await axios.request(originalRequest);
            }
        }

        // Handle 500
        if (error.response && error.response.status === 500) {
            error.endpoint = error.config?.url;
            error.payload = error.config?.data;
            error.method = error.config?.method;
            error.headers = error.config?.headers;
        }

        return Promise.reject(error);
    },
);

axios.interceptors.request.use(
    async (config) => {
        if (
            !PUBLIC_API_URLS.some((publicUrl) =>
                matchesRoute(config.url, publicUrl),
            )
        ) {
            // If the url is under authentication, we get the privy token
            const token = await getPrivyAccessTokenOrLogout(
                `API request interceptor on ${config.url}`,
            );
            if (token) {
                const decodedPrivyAccessToken = jwtDecode(token);
                if (decodedPrivyAccessToken.iat) {
                    const timeBeforePrivyTokenValidityInMs =
                        differenceInMilliseconds(
                            new Date(decodedPrivyAccessToken.iat * 1_000),
                            new Date(),
                        );
                    // when privy generate a new token, the validity is in the future (generally 2 sec after now)
                    if (timeBeforePrivyTokenValidityInMs > 0) {
                        await sleep(timeBeforePrivyTokenValidityInMs);
                    }
                }
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export async function isBackendAvailable() {
    const response = await axios.get(`/`);

    return response.data.success;
}

export async function sendChatMessageToBackend(
    messageIdentifier: Hex,
    message: string,
    expectedRecipient: string,
) {
    try {
        const params: ObjectToCamel<PostChatMessageRequestArgs> = {
            text: message,
            to: expectedRecipient,
        };
        const payload = await axios.post<ChatMessage>(
            `/messages/${messageIdentifier}/chat`,
            objectToSnake(params),
        );
        return objectToCamel(payload.data);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function sendMessageTLP(
    params: ObjectToCamel<PostMessageDataRequestArgs>,
) {
    try {
        const response = await axios.post<PostMessageDataResponse>(
            "/messages/",
            objectToSnake(params),
        );

        return objectToCamel(response.data).messageIdentifier;
    } catch (e: unknown) {
        const error = e as AxiosError;
        throw error;
    }
}

export async function uploadAttachments(
    messageIdentifier: Hex,
    formData: FormData,
) {
    try {
        const response = await axios.post<SuccessResponse>(
            `/messages/${messageIdentifier}/attachments`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getMessageFromBackend(messageIdentifier: Hex) {
    try {
        const response = await axios.get<
            EnhancedMessageData & MessageComputedFields
        >(`/messages/${messageIdentifier}`);

        const tlpData = objectToCamel(response.data).tlpData;
        return {
            ...objectToCamel(response.data),
            tlpData: tlpData
                ? objectToCamel<ParsedTLPData>(JSON.parse(tlpData))
                : null,
        };
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
        }
        throw error;
    }
}

export async function getMessagesFromBackend(
    topicId: number,
    skip = 0,
    take = TAKE_MESSAGES_AMOUNT,
) {
    try {
        const params: ObjectToCamel<GetSkipTakeRequestArgs> = {
            take,
            skip,
        };
        const response = await axios.get<EnhancedGetMessagesResponse>(
            `/topics/${topicId}/messages`,
            {
                params: objectToSnake(params),
            },
        );
        const data = objectToCamel(response.data);
        const messages = data.messages.map((message) => ({
            ...message,
            tlpData: message.tlpData
                ? objectToCamel<ParsedTLPData>(JSON.parse(message.tlpData))
                : null,
        }));
        return {
            ...data,
            messages,
        };
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
        }
        throw error;
    }
}

export async function getChatMessagesFromBackend(
    messageIdentifier: Hex,
    skip = 0,
    take = TAKE_MESSAGES_AMOUNT,
) {
    try {
        const params: ObjectToCamel<GetSkipTakeRequestArgs> = {
            take,
            skip,
        };
        const response = await axios.get<GetChatMessagesResponse>(
            `/messages/${messageIdentifier}/chat`,
            {
                params: objectToSnake(params),
            },
        );

        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
        }
        throw error;
    }
}

export async function setMessageReadFromBackend(messageIdentifier: Hex) {
    try {
        const response = await axios.post<SuccessResponse>(
            `/messages/${messageIdentifier}/read`,
        );
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        console.error(error);
        throw error;
    }
}

export async function addVoteOnMessage(
    messageIdentifier: Hex,
    isUpvote: ObjectToCamel<PostVoteRequestArgs>["isUpvote"],
) {
    try {
        const response = await axios.post<SuccessResponse>(
            `/messages/${messageIdentifier}/votes`,
            objectToSnake({
                isUpvote,
            }),
        );
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        console.error(error);
        throw error;
    }
}

export async function getProfileFromBackend(walletAddress: string) {
    const response = await axios.get<EnhancedGetProfileResponse>(
        `/profiles/${walletAddress}`,
    );
    const profileData = objectToCamel(response.data);

    return { ...profileData.profile, ...profileData.metadata };
}

export async function getUserRevenue() {
    try {
        const response = await axios.get<RevenueData>(`/profiles/revenue`);
        const transformedData = objectToCamel(response.data);
        return transformedData;
    } catch (e: unknown) {
        const error = e as AxiosError;
        throw new Error(error.message || "Error making request");
    }
}

export async function savePublicKey(
    publicKey: ObjectToCamel<PostPublicKeyRequestArgs>["publicKey"],
) {
    const response = await axios.post<SuccessResponse>(
        `/public-keys/`,
        objectToSnake({
            publicKey,
        }),
    );

    return objectToCamel(response.data);
}

export async function readPublicKey(address: string) {
    try {
        const response = await axios.get<GetPublicKeyResponse>(
            `/public-keys/${address}`,
        );

        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        throw error;
    }
}

export async function logout() {
    const response = await axios.get<SuccessResponse>(`/auth/logout`);

    return objectToCamel(response.data);
}

export async function setPushNotificationStatus(
    topicId: number,
    notificationType: NotificationType,
    subscriptionStatus: boolean,
) {
    const response = await axios.post<SuccessResponse>(
        `/push-notifications/${topicId}/${notificationType}/${subscriptionStatus}`,
    );

    return objectToCamel(response.data);
}

export async function getPushNotificationStatus(
    topicId: number,
    notificationType: NotificationType,
) {
    const response = await axios.get<GetPushNotificationSubscriptionResponse>(
        `/push-notifications/${topicId}/${notificationType}`,
    );

    return response.data.subscribed;
}

export async function getAllPushNotificationStatus(topicId: number) {
    const response =
        await axios.get<GetAllPushNotificationSubscriptionResponse>(
            `/push-notifications/${topicId}`,
        );

    return response.data.push_notifications as Record<
        NotificationType,
        boolean
    >;
}

export async function createNewTopic(
    params: ObjectToCamel<PostTopicsRequestArgs>,
) {
    try {
        const response = await axios.post<PostTopicsResponse>(
            `/topics/`,
            params,
        );
        const transformedData = objectToCamel(response.data);
        return transformedData;
    } catch (e: unknown) {
        const error = e as AxiosError;
        console.error("Error creating topic request", error);
        throw error;
    }
}

export async function getTopicsFromBackend(
    skip = 0,
    take = 20,
    term = null,
    subscriberAddress: string | null = null,
) {
    try {
        const params: ObjectToCamel<TopicsQueryArgs> = {
            skip,
            take,
            term,
            subscriberAddress,
        };

        const response = await axios.get<GetTopicsResponse>("/topics/", {
            params: objectToSnake(params),
        });

        const transformedData = objectToCamel(response.data);

        return transformedData;
    } catch (e: unknown) {
        const error = e as AxiosError;
        console.error(error);
        throw error;
    }
}

export async function getTopicFromBackend(topicId: number) {
    try {
        const response = await axios.get<GetTopicsResponse>(
            `/topics/${topicId}`,
        );
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
        }
        throw error;
    }
}

export async function readFeedSubscriptions(
    topicId: number,
    skip = 0,
    take = TAKE_SUBSCRIBERS_AMOUNT,
) {
    const params: ObjectToCamel<GetFeedSubscribersRequestArgs> = {
        take,
        skip,
    };
    const response = await axios.get<EnhancedGetFeedSubscribersResponse>(
        `/topics/${topicId}/subscribers`,
        {
            params: objectToSnake(params),
        },
    );

    return objectToCamel(response.data);
}

export async function readFeedSubscribersToBeKickedOut(topicId: number) {
    const response =
        await axios.get<GetSubscriberAddressesToBeKickedOutResponse>(
            `/topics/${topicId}/subscribers/marked-to-be-kicked-out`,
        );

    return response.data.addresses;
}

export async function getTopicPublisherStatusFromBackend(
    topicId: number,
    publisherAddress: string,
) {
    try {
        const response = await axios.get<EnhancedProfileData>(
            `/topics/${topicId}/publishers/${publisherAddress}`,
        );

        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
            throw error;
        }
        return false;
    }
}

export async function auth(
    params?: ObjectToCamel<PostAuthRequestArgs> | undefined,
) {
    const response = await axios.post<EnhancedPostAuthResponse>(
        `/auth/`,
        params ? objectToSnake(params) : {},
    );
    const camelCasedData = objectToCamel(response.data);
    return { ...camelCasedData.profile, ...camelCasedData.metadata };
}

export async function updateSocialData() {
    const response = await axios.post<EnhancedPostAuthResponse>(
        `/profiles/update-social-data`,
    );

    const camelCasedData = objectToCamel(response.data);
    return { ...camelCasedData.profile, ...camelCasedData.metadata };
}

export async function setDealtWithNewWalletInfoOnProfile() {
    const response = await axios.post<SuccessResponse>(
        `/profiles/set-dealt-with-new-wallet-info`,
    );
    return response;
}

/**
 * Get the exchange rates of a currency
 * @param refCurrencySymbol - reference currency
 * @param rateCurrenciesSymbols - currencies in which the exchange rate is calculated
 * @returns {Promise<Record<string, number>>}
 */
export async function getCurrencyRate(
    refCurrencySymbol = CURRENCY_SYMBOL.ETH,
    rateCurrenciesSymbols = [CURRENCY_SYMBOL.USD],
) {
    const headers: Record<string, string> = {};
    const apiKey = import.meta.env.VITE_CRYPTO_COMPARE_API_KEY;
    if (apiKey) {
        headers["authorization"] = `apiKey ${apiKey}`;
    }
    const rawResponse = await axios.get<Record<string, number>>(
        `https://min-api.cryptocompare.com/data/price?fsym=${refCurrencySymbol}&tsyms=${rateCurrenciesSymbols.join(
            ",",
        )}`,
        {
            headers,
        },
    );
    return rawResponse.data;
}

export async function deleteKickedOutSubscriptionFromBackend(
    topicId: number,
    accountAddress: string,
) {
    try {
        await axios.delete<SuccessResponse>(
            `/topics/${topicId}/kicked_out_subscriptions/${accountAddress}`,
        );
    } catch (e: unknown) {
        const error = e as AxiosError;
        console.error("Error when deleting the subscription: ", error);
        throw error;
    }
}

export async function getFeedKickedOutSubscriptionFromBackend(
    topicId: number,
    accountAddress: string,
) {
    try {
        const response = await axios.get<KickedOutSubscription>(
            `/topics/${topicId}/kicked_out_subscriptions/${accountAddress}`,
        );
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
            throw error;
        }
    }
}

export async function getProfileFromReferralCode(referralCode: string) {
    try {
        const response = await axios.get<ProfileData>(
            `/profiles/referral-code/${referralCode}`,
        );
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
        }
        throw error;
    }
}

export async function createUserReferral(referralCode: string) {
    try {
        const params: ObjectToCamel<PostReferralRequestArgs> = { referralCode };
        const response = await axios.post(
            `/profiles/referral`,
            objectToSnake(params),
        );
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
        }
        throw error;
    }
}

export async function getProfileSocialHandles(walletAddress: Address) {
    try {
        const response = await axios.get<ProfileData>(
            `/profiles/${walletAddress}/social-network-handles`,
        );
        return response.data;
    } catch (e: unknown) {
        const error = e as AxiosError;

        if (error.response && error.response.status !== 404) {
            console.error(error);
        }
        throw error;
    }
}

export async function getLatestLottery() {
    try {
        const response = await axios.get<LotteryEvent>(`/lotteries/latest`);
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status === 404) {
            return null;
        }
        if (error.response && error.response.status !== 404) {
            console.error(error);
        }
        throw error;
    }
}

export async function getLotteryPoints() {
    try {
        const response =
            await axios.get<GetCurrentUserPointsResponse>(`/lotteries/points`);
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
        }
        throw error;
    }
}

export async function getCurrentUserLotteryPrizeToRedeem() {
    try {
        const response = await axios.get<LotteryEventWinner>(
            `/lotteries/prize-to-redeem`,
        );
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
            throw error;
        }
        return null;
    }
}

export async function declineLotteryPrize(lotteryEventWinnerId: string) {
    try {
        const response = await axios.put<LotteryEventWinner>(
            `/lotteries/event-winner/${lotteryEventWinnerId}/decline`,
        );
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
            throw error;
        }
    }
}

export async function sendSocialNetworkLinkToRedeemPrize(
    lotteryEventWinnerId: string,
    socialNetworkLink: string,
) {
    try {
        const params: ObjectToCamel<PutLotteryEventWinnerArgs> = {
            socialNetworkLink,
        };

        const response = await axios.put<LotteryEventWinner>(
            `/lotteries/event-winner/${lotteryEventWinnerId}`,
            objectToSnake(params),
        );
        return objectToCamel(response.data);
    } catch (e: unknown) {
        const error = e as AxiosError;
        if (error.response && error.response.status !== 404) {
            console.error(error);
            throw error;
        }
    }
}
