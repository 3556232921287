/* global BigInt, */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";

import {
    getLatestLottery as getLatestLotteryFromBackend,
    getLotteryPoints as getLotteryPointsFromBackend,
} from "src/services/api";

import { AppDispatch, RootState } from "src/store";
import { LotteryEvent } from "src/types/api-interfaces";

interface ILotteriesState {
    latest: ObjectToCamel<LotteryEvent> | null;
    points: number;
}

export const lotteriesSlice = createSlice({
    name: "lotteries",
    initialState: {
        latest: null,
    } as ILotteriesState,
    reducers: {
        setCurrentLottery: (
            state,
            {
                payload,
            }: PayloadAction<{
                latestLottery: ObjectToCamel<LotteryEvent> | null;
            }>,
        ) => {
            state.latest = payload.latestLottery;
        },
        setPoints: (state, { payload }: PayloadAction<{ points: number }>) => {
            state.points = payload.points;
        },
    },
});

export const { setCurrentLottery, setPoints } = lotteriesSlice.actions;

export const getLatestLottery = () => async (dispatch: AppDispatch) => {
    const latestLottery = await getLatestLotteryFromBackend();
    dispatch(
        setCurrentLottery({
            latestLottery,
        }),
    );
};

export const getLotteryPoints = () => async (dispatch: AppDispatch) => {
    const { points } = await getLotteryPointsFromBackend();
    dispatch(
        setPoints({
            points,
        }),
    );
};

export const selectLatestLottery = () => {
    return function selectLatestLotteryFromState(state: RootState) {
        return state.lotteries.latest;
    };
};

export const selectLotteryPoints = () => {
    return function selectLotteryPointsFromState(state: RootState) {
        return state.lotteries.points ?? 0;
    };
};

export default lotteriesSlice.reducer;
