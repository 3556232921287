import { Button, Stack, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getTopic, selectTopic } from "src/slices/topicsSlice";

import ShareOnSocialNetworkButton from "src/components/ShareOnSocialNetworkButton";
import StyledModal from "src/components/StyledModal";

import { ROUTES, SOCIAL_NETWORK_SHARE_MESSAGES } from "src/constants";
import { JustSubscribedContext } from "src/contexts";

export default function CongratsSubscriptionModal() {
    const { topicId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { subscribed, setSubscribed } = useContext(JustSubscribedContext);
    const topic = useSelector(selectTopic(topicId));

    useEffect(() => {
        if (!topic && topicId) {
            dispatch(getTopic(topicId));
        }
    }, [dispatch, topic, topicId]);

    return (
        <StyledModal
            open={subscribed}
            onClose={() => setSubscribed(false)}
            aria-labelledby={"congrats-title"}
            aria-describedby={"congrats-description"}
        >
            <Stack gap={1}>
                <Typography id="congrats-title" variant="h6">
                    You're in!
                </Typography>
                <Typography id="congrats-description" color="secondary">
                    You are now a queue member of this topic.
                </Typography>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        setSubscribed(false);
                        navigate(ROUTES.FEED(topicId));
                    }}
                >
                    See the feed
                </Button>
                <ShareOnSocialNetworkButton
                    messageFunction={
                        SOCIAL_NETWORK_SHARE_MESSAGES.NEW_SUBSCRIBER
                    }
                />
            </Stack>
        </StyledModal>
    );
}
