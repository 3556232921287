import { Stack, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getTopic, selectTopic } from "src/slices/topicsSlice";

export default function FeedHeaderTitle({
    prefix = "",
    text,
    postfix = "",
    ...props
}) {
    const dispatch = useDispatch();
    const { topicId } = useParams();
    const topic = useSelector(selectTopic(topicId));
    const topicLoadedRef = useRef();

    useEffect(() => {
        if (!topic && !topicLoadedRef.current) {
            topicLoadedRef.current = true;
            dispatch(getTopic(topicId));
        }
    }, [dispatch, topicId, topic]);

    return (
        <Stack
            flexDirection="column"
            flex={1}
            display="-webkit-box"
            overflow="hidden"
            sx={{
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
                mr: 1,
                ...(props.sx || {}),
            }}
            {...props}
        >
            <Typography
                fontWeight="bold"
                lineHeight={1}
                sx={{ fontSize: "18px", fontWeight: 800 }}
            >
                {`${prefix ? `${prefix} - ` : ""} ${text || topic?.name} ${
                    postfix ? ` - ${postfix}` : ""
                }`}
            </Typography>
        </Stack>
    );
}
