import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { selectCurrentUserWalletAddress } from "src/slices/authSlice";

import DepositOptions from "src/components/DepositOptions";
import SkipButton from "src/components/SkipButton";

export default function FundWallet({
    address,
    title = "Choose how you would like to fund your wallet on Base",
    subTitle = "",
    skipLabel = "Skip & finish",
    onSkip,
}) {
    const allowToSkip = Boolean(onSkip);
    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );

    return (
        <Stack flex={1} width="100%">
            <Stack flex={1} width="280px" alignSelf="center" gap={2} mb={2}>
                <Typography textAlign="center" variant="h6">
                    {title}
                </Typography>
                {subTitle && (
                    <Typography textAlign="center" color="secondary">
                        {subTitle}
                    </Typography>
                )}
            </Stack>
            <Stack gap={2} textAlign="center">
                <DepositOptions address={currentUserWalletAddress} />
                {allowToSkip && (
                    <SkipButton
                        text={skipLabel}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (onSkip) {
                                onSkip();
                            }
                        }}
                    />
                )}
            </Stack>
        </Stack>
    );
}
