import { Stack } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
    getCurrentUserSubscription,
    selectCurrentUserSubscriptionTo,
    selectLoading as selectLoadingSubscriptions,
} from "src/slices/subscriptionsSlice";

import SubscribeButton from "src/components/SubscribeButton";

import { ContractsContext } from "src/contexts";

export default function FeedSubHeader({ sx = {}, ...props }) {
    const dispatch = useDispatch();
    const { topicId } = useParams();
    const { murmurContract } = useContext(ContractsContext);

    const currentUserSubscriptionLoadedRef = useRef();
    const subscription = useSelector(selectCurrentUserSubscriptionTo(topicId));
    const loadingUserSub = useSelector(
        selectLoadingSubscriptions(`currentUserSubTo${topicId}`),
    );

    useEffect(() => {
        // If someone else is already loading the sub, let's disable the hook
        if (loadingUserSub) {
            currentUserSubscriptionLoadedRef.current = true;
        }

        if (
            topicId &&
            !loadingUserSub &&
            !currentUserSubscriptionLoadedRef.current
        ) {
            dispatch(getCurrentUserSubscription(murmurContract, topicId));
        }
    }, [murmurContract, topicId, dispatch, loadingUserSub]);

    if (
        (!loadingUserSub || currentUserSubscriptionLoadedRef.current) &&
        !subscription
    ) {
        return (
            <Stack
                flexDirection="column"
                flex={1}
                sx={{ p: 2, pt: 1, ...sx }}
                {...props}
            >
                <SubscribeButton topicId={topicId} />
            </Stack>
        );
    }

    return null;
}
