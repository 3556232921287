import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { differenceInHours } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import useBalance from "src/hooks/useBalance";
import useSocketUpdates from "src/hooks/useSocketUpdates";
import {
    selectCurrentUserWalletAddress,
    selectIsConnected,
} from "src/slices/authSlice";
import {
    getLatestLottery,
    getLotteryPoints,
    selectLatestLottery,
    selectLotteryPoints,
} from "src/slices/lotteriesSlice";
import {
    getCurrentUserProfile,
    selectCurrentUserProfile,
} from "src/slices/profileSlice";

import CurrencyDisplay from "src/components/CurrencyDisplay";
import CurrentUserAvatar from "src/components/CurrentUserAvatar";
import LotteryInfoModal from "src/components/LotteryInfoModal";

import EthereumIcon from "src/assets/ethereum.svg?react";
import { CURRENCY_SYMBOL, ROUTES } from "src/constants";
import { formatRemainingTime, getLotteryFetchInterval } from "src/utils";

export default function UserBalances({
    showCurrentUserAvatar = false,
    onTopic = false,
}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );
    const [lotteryInfoModalOPen, setLotteryInfoModalOpen] = useState(false);
    const latestLottery = useSelector(selectLatestLottery());

    const isConnected = useSelector(selectIsConnected);

    const points = useSelector(selectLotteryPoints());
    const { balance } = useBalance({
        address: currentUserWalletAddress,
        fetchInterval: 10_000,
    });

    const onLotteryPointsUpdate = useCallback(async () => {
        dispatch(getLotteryPoints());
    }, [dispatch]);

    useEffect(() => {
        if (currentUserWalletAddress) {
            onLotteryPointsUpdate();
        }
    }, [onLotteryPointsUpdate, currentUserWalletAddress]);

    const fetchLotteryInterval = getLotteryFetchInterval(latestLottery);
    useEffect(() => {
        let timer;
        if (location.pathname !== ROUTES.HOME) {
            dispatch(getLatestLottery());
            timer = setInterval(async () => {
                dispatch(getLatestLottery());
            }, fetchLotteryInterval);
        }
        return timer ? () => clearInterval(timer) : undefined;
    }, [dispatch, location.pathname, fetchLotteryInterval]);

    let displayLotteryCountDown = false;
    if (
        latestLottery?.startDate &&
        new Date(latestLottery?.startDate) < Date.now() &&
        latestLottery?.endDate &&
        new Date(latestLottery?.endDate) > Date.now()
    ) {
        displayLotteryCountDown = true;
    }

    useSocketUpdates(
        `lottery_points_${currentUserWalletAddress}`,
        onLotteryPointsUpdate,
        onLotteryPointsUpdate,
    );
    const theme = useTheme();
    const profile = useSelector(selectCurrentUserProfile);

    useEffect(() => {
        if (!profile && isConnected) {
            dispatch(getCurrentUserProfile());
        }
    }, [dispatch, profile, isConnected]);

    if (!isConnected) {
        return showCurrentUserAvatar ? (
            <Stack
                spacing={1}
                justifySelf="flex-end"
                alignItems="center"
                direction="row"
                sx={{
                    p: 1,
                }}
            >
                <CurrentUserAvatar profile={profile} />
            </Stack>
        ) : null;
    }

    return (
        <Box display="flex" flexDirection="column" alignSelf="flex-end">
            <Stack
                spacing={1}
                justifyContent="center"
                alignItems="center"
                direction="row"
                sx={{
                    backgroundColor: onTopic
                        ? "background.main"
                        : "primary.dark",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    borderBottomLeftRadius: displayLotteryCountDown
                        ? 0
                        : "12px",
                    borderBottomRightRadius: displayLotteryCountDown
                        ? 0
                        : "12px",
                    p: 1,
                    cursor: "pointer",
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    onClick={() => {
                        location.pathname !== ROUTES.PROFILE &&
                            navigate(ROUTES.PROFILE, {
                                state: { from: location.pathname },
                            });
                    }}
                >
                    <LocalActivityIcon color="primary" />
                    &nbsp;{points ?? 0}
                </Stack>
                <Stack
                    spacing={1}
                    direction="row"
                    onClick={() => {
                        location.pathname !== ROUTES.PROFILE &&
                            navigate(ROUTES.PROFILE, {
                                state: { from: location.pathname },
                            });
                    }}
                >
                    <Stack direction="row" alignItems="center">
                        <EthereumIcon
                            style={{ color: theme.palette.primary.main }}
                        />
                        <CurrencyDisplay
                            amount={balance || 0}
                            currency={CURRENCY_SYMBOL.ETH}
                            convertFrom={CURRENCY_SYMBOL.WEI}
                            toFixed={balance ? 2 : 0}
                            showUnit={false}
                        />
                    </Stack>
                    {showCurrentUserAvatar && (
                        <CurrentUserAvatar profile={profile} />
                    )}
                </Stack>
            </Stack>
            {displayLotteryCountDown ? (
                <>
                    <Stack
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        sx={{
                            backgroundColor: "yellow.main",
                            borderBottomLeftRadius: "12px",
                            borderBottomRightRadius: "12px",
                            p: 1,
                            cursor: "pointer",
                        }}
                        onClick={() => setLotteryInfoModalOpen(true)}
                    >
                        <AutoAwesomeIcon
                            color="background"
                            sx={{ fontSize: 15 }}
                        />
                        <Typography
                            variant="body2"
                            color="background.main"
                            fontWeight={700}
                        >
                            {formatRemainingTime({
                                dateString: latestLottery.endDate,
                                shouldIncludeSeconds:
                                    differenceInHours(
                                        new Date(latestLottery.endDate),
                                        new Date(),
                                    ) < 1,
                            })}
                        </Typography>
                    </Stack>
                    <LotteryInfoModal
                        open={lotteryInfoModalOPen}
                        onClose={() => {
                            setLotteryInfoModalOpen(false);
                        }}
                    />
                </>
            ) : null}
        </Box>
    );
}
