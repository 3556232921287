import { useTheme } from "@emotion/react";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { Box, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import CurrencyDisplay from "src/components/CurrencyDisplay";
import ShareOnSocialNetworkButton from "src/components/ShareOnSocialNetworkButton";
import StyledModal from "src/components/StyledModal";

import EthereumIcon from "src/assets/ethereum.svg?react";
import {
    CURRENCY_SYMBOL,
    MAX_LOTTERY_PRIZE_DECIMALS,
    ROUTES,
    SOCIAL_NETWORK_SHARE_MESSAGES,
} from "src/constants";
import { MessageRevealedContext } from "src/contexts";

export default function CongratsRevealMessageModal() {
    const { topicId } = useParams();
    const theme = useTheme();

    const { revealedMessage, setRevealedMessage } = useContext(
        MessageRevealedContext,
    );

    if (!topicId || !revealedMessage) {
        // Do not render if we're not on a topic page
        return null;
    }

    const handleClose = () => setRevealedMessage(null);

    return (
        <StyledModal
            open={Boolean(revealedMessage)}
            onClose={handleClose}
            aria-labelledby={"congrats-title"}
            aria-describedby={"congrats-description"}
        >
            <Stack gap={1}>
                <Typography id="congrats-title" variant="h6">
                    Congrats!
                </Typography>
                <Box p="0.5rem 0">
                    <Typography id="congrats-description" color="secondary">
                        You earned&nbsp;
                        {revealedMessage.revealerLotteryPoints ?? 0}
                        &nbsp;
                        <LocalActivityIcon
                            color="secondary"
                            style={{ verticalAlign: "middle" }}
                        />
                        &nbsp;and&nbsp;
                        <CurrencyDisplay
                            amount={revealedMessage.revealerRevenueWei || 0}
                            currency={CURRENCY_SYMBOL.ETH}
                            convertFrom={CURRENCY_SYMBOL.WEI}
                            toFixed={
                                revealedMessage.revealerRevenueWei
                                    ? MAX_LOTTERY_PRIZE_DECIMALS
                                    : 0
                            }
                            showUnit={false}
                        />
                        <EthereumIcon
                            style={{
                                color: theme.palette.secondary.main,
                                verticalAlign: "middle",
                            }}
                        />
                        &nbsp;by successfully revealing the post.
                    </Typography>
                </Box>

                <ShareOnSocialNetworkButton
                    messageFunction={
                        SOCIAL_NETWORK_SHARE_MESSAGES.REVEALED_MESSAGE
                    }
                    messageExtraParams={{
                        postLink: `${import.meta.env.VITE_FRONTEND_HOST}${ROUTES.MESSAGE(revealedMessage.topic?.id, revealedMessage.messageIdentifier)}`,
                        ownerUsername: revealedMessage.owner?.username,
                    }}
                />
            </Stack>
        </StyledModal>
    );
}
