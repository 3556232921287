import WifiOffIcon from "@mui/icons-material/WifiOff";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { usePrivy, useToken } from "@privy-io/react-auth";
import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";
import OneSignal from "react-onesignal";
import { useDispatch, useSelector } from "react-redux";
import {
    Outlet,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";

import useCurrentPrivyAccount from "src/hooks/useCurrentPrivyAccount";
import useGenerateKeyPairIfNeeded from "src/hooks/useGenerateKeyPairIfNeeded";
import useMurmurContracts from "src/hooks/useMurmurContracts";
import { isBackendAvailable } from "src/services/api";
import {
    handleAuthConnection,
    logoutFromAll,
    selectIsConnected,
    selectIsLoggingOut,
    setIsConnectingPrivy,
} from "src/slices/authSlice";
import {
    getContractSettings,
    selectContractSettings,
} from "src/slices/contractSettingsSlice";
import {
    getEpochSettingsFromContract,
    selectEpochSettings,
} from "src/slices/epochSettingsSlice";
import {
    selectNoConnection,
    setNoConnection,
} from "src/slices/noConnectionSlice";
import { setSnackbarFeedback } from "src/slices/snackbarFeedbackSlice";

import ConnectedSnackbar from "src/components/ConnectedSnackbar";
import FullscreenCircularProgress from "src/components/FullscreenCircularProgress";
import FullscreenMessage from "src/components/FullscreenMessage";
import NoFundsModal from "src/components/NoFundsModal";
import PageContainer from "src/components/PageContainer";
import PageProtectionWrapper from "src/components/PageProtectionWrapper";

import AppContextsProvider from "src/AppContextsProvider";
import { LocalStorageKeys, ROUTES } from "src/constants";
import { clearStorageValue } from "src/localStorage";
import FeedJoinPage from "src/routes/FeedJoinPage";
import PrivacyPolicyPage from "src/routes/PrivacyPolicyPage";
import { socket } from "src/socket";
import { getPrivyAccessTokenOrLogout } from "src/utils/getPrivyAccessTokenOrLogout";

let oneSignalInitialized = false;

if (!oneSignalInitialized) {
    OneSignal.init({
        appId: import.meta.env.VITE_ONE_SIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: Boolean(
            import.meta.env.VITE_ONE_SIGNAL_IS_DEV,
        ),
        welcomeNotification: {
            disable: true,
        },
        promptOptions: {
            slidedown: {
                prompts: [
                    {
                        type: "push", // current types are "push" & "category"
                        autoPrompt: false,
                        text: {
                            /* limited to 90 characters */
                            actionMessage:
                                "Enable notifications and never miss the action on Murmur",
                            /* acceptButton limited to 15 characters */
                            acceptButton: "Allow",
                            /* cancelButton limited to 15 characters */
                            cancelButton: "Cancel",
                        },
                        delay: {
                            pageViews: 1,
                            timeDelay: 0,
                        },
                    },
                ],
            },
        },
        serviceWorkerParam: { scope: "/push/onesignal/" },
        serviceWorkerPath: "push/onesignal/OneSignalSDKWorker.js",
    });

    oneSignalInitialized = true;
}

const ExplorePage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "ExplorePage" */ "src/routes/ExplorePage"
        ),
);
const FeedAboutPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "FeedAboutPage" */ "src/routes/FeedAboutPage"
        ),
);
const FeedChangeSpotPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "FeedChangeSpotPage" */ "src/routes/FeedChangeSpotPage"
        ),
);
const FeedDetailsPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "FeedDetailsPage" */ "src/routes/FeedDetailsPage"
        ),
);
const FeedNotificationsPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "FeedNotificationsPage" */ "src/routes/FeedNotificationsPage"
        ),
);

const FeedSubscribersPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "FeedSubscribersPage" */ "src/routes/FeedSubscribersPage"
        ),
);
const MySubscriptionsPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "MySubscriptionsPage" */ "src/routes/MySubscriptionsPage"
        ),
);
const NotFoundPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "NotFoundPage" */ "src/routes/NotFoundPage"
        ),
);
const CreatePostPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "CreatePostPage" */ "src/routes/CreatePostPage"
        ),
);
const PostPage = React.lazy(
    async () =>
        await import(/* webpackChunkName: "PostPage" */ "src/routes/PostPage"),
);
const PreviewPostPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "PreviewPostPage" */ "src/routes/PreviewPostPage"
        ),
);
const ProfilePage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "ProfilePage" */ "src/routes/ProfilePage"
        ),
);
const StartPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "StartAndTopicsPages" */ "src/routes/StartPage"
        ),
);
const TopicsPage = React.lazy(
    async () =>
        await import(
            /* webpackChunkName: "StartAndTopicsPages" */ "src/routes/TopicsPage"
        ),
);

const App = () => {
    const { contracts, error: contractsError } = useMurmurContracts();
    const { murmurContract } = contracts;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const account = useCurrentPrivyAccount();
    const isConnected = useSelector(selectIsConnected);
    const { ready, authenticated, user, unlinkGoogle, unlinkEmail } =
        usePrivy();
    useGenerateKeyPairIfNeeded({
        walletAddress: account?.address,
    });
    const isLoggingOut = useSelector(selectIsLoggingOut);

    const [deferredPrompt, setDeferredPrompt] = useState();
    const location = useLocation();
    const socketConnectionRef = useRef();

    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [reactGAInitialized, setReactGAInitialized] = useState(false);

    const backendCheckRef = useRef();
    const [backendCheckLoading, setBackendCheckLoading] = useState(true);
    const [backendCheckError, setBackendCheckError] = useState();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isMultiTopicsTabsEnabled =
        import.meta.env.VITE_ENABLED_MULTI_TOPICS_TABS === "true";

    const noConnection = useSelector(selectNoConnection);
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("referralCode");

    useToken({
        onAccessTokenGranted: (accessToken) => {
            // This will be called when a user logs in, or when a user's access token is refreshed.
            console.info("New privy access token provided");
        },
        onAccessTokenRemoved: () => {
            if (location.pathname !== "/profile") {
                // we log an error if privy access token was removed without user logout action
                console.warn(
                    "Privy access token removed without user logout action",
                );
            }
            // To handle user logout, we always only logout from privy in components
            // and here, when privy user is fully logged out, we logout from anything else (backend, OneSignal, redux store, local storage values...)
            if (isConnected) {
                dispatch(logoutFromAll());
            }
            if (
                ![ROUTES.START, ROUTES.PRIVACY_POLICY].includes(
                    location.pathname,
                )
            ) {
                navigate(
                    ROUTES.START,
                    location.pathname !== "/profile"
                        ? { state: { from: location.pathname } }
                        : undefined,
                );
            }
        },
    });

    useEffect(() => {
        if (import.meta.env.VITE_GOOGLE_ANALYTICS_ID !== "none") {
            ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_ID, {
                standardImplementation: true,
            });
            setReactGAInitialized(true);
        }
    }, []);

    // Manage online status
    useEffect(() => {
        const onlineListener = () => {
            dispatch(setNoConnection(false));
        };
        window.addEventListener("online", onlineListener);
        const offlineListener = () => {
            dispatch(setNoConnection(true));
        };
        window.addEventListener("offline", offlineListener);

        if (!window.navigator.onLine) {
            dispatch(setNoConnection(true));
        }

        return () => {
            window.removeEventListener("online", onlineListener);
            window.removeEventListener("offline", offlineListener);
        };
    }, [dispatch]);

    useEffect(() => {
        if (reactGAInitialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [location, reactGAInitialized]);

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            if (e) {
                const body = document.getElementsByTagName("body")[0];
                body.style.overflow = "auto"; // To enable global pull to refresh if used on navigator
            }
        });
        const intialStartPage = document.querySelector(".initial-loading-page");
        if (intialStartPage) {
            intialStartPage.remove();
        }
        async function backendReachabalityCheck() {
            setBackendCheckLoading(true);
            backendCheckRef.current = true;
            try {
                const status = await isBackendAvailable();
                if (!status) {
                    throw Error("Back-end not available");
                }
            } catch (e) {
                console.error(e);
                setBackendCheckError(
                    "The server seems unavailable at the moment.\nPlease come back in a bit!",
                );
            } finally {
                setBackendCheckLoading(false);
                backendCheckRef.current = false;
            }
        }

        if (!backendCheckRef.current) {
            backendReachabalityCheck();
            clearStorageValue(LocalStorageKeys.SIGNATURE); // not used anymore, but clear for users who still have it
        }
    }, [dispatch]);

    const privyUserIsFullyAuthenticated = Boolean(
        ready && authenticated && user?.wallet,
    );
    const isPrivacyPolicyPage = location.pathname === ROUTES.PRIVACY_POLICY;

    useEffect(() => {
        async function cleanupOldLinks() {
            if (
                privyUserIsFullyAuthenticated &&
                user?.linkedAccounts?.length > 2
            ) {
                const emailAccounts = user?.linkedAccounts.filter(
                    ({ type }) => type === "email" || type === "google_oauth",
                );
                const socialAccounts = user?.linkedAccounts.filter(
                    ({ type }) =>
                        type === "farcaster" || type === "twitter_oauth",
                );

                if (socialAccounts.length > 0 && emailAccounts.length > 0) {
                    for (const account of emailAccounts) {
                        switch (account.type) {
                            case "google_oauth":
                                console.log(
                                    `Unlinking Google account: ${account.subject}`,
                                );
                                await unlinkGoogle(account.subject);
                                break;
                            case "email":
                                console.log(
                                    `Unlinking Email account: ${account.address}`,
                                );
                                await unlinkEmail(account.address);
                                break;
                            default:
                                console.warn(
                                    `Email account that's not google or email???`,
                                    account,
                                );
                        }
                    }
                }
            }
        }

        cleanupOldLinks();
    }, [privyUserIsFullyAuthenticated, unlinkEmail, unlinkGoogle, user]);

    useEffect(() => {
        if (
            !isConnected &&
            account?.address &&
            privyUserIsFullyAuthenticated &&
            !isLoggingOut &&
            !isPrivacyPolicyPage
        ) {
            handleAuthConnection({
                walletAddress: account.address,
                referralCode,
            })(dispatch);
        }
    }, [
        isConnected,
        account.address,
        privyUserIsFullyAuthenticated,
        isLoggingOut,
        isPrivacyPolicyPage,
        referralCode,
        dispatch,
    ]);

    // manage state of privy "loading"
    useEffect(() => {
        dispatch(
            setIsConnectingPrivy(
                !ready || (ready && authenticated && !account?.address),
            ),
        );
    }, [dispatch, ready, authenticated, account.address]);

    // socket connection
    useEffect(() => {
        if (
            account?.address &&
            privyUserIsFullyAuthenticated &&
            isConnected &&
            !socketConnectionRef.current
        ) {
            socketConnectionRef.current = true;
            function onConnect() {
                console.debug("Connected to websocket", socket.connected);
                setIsSocketConnected(socket.connected);
            }

            async function onConnectError(payload) {
                console.debug("onConnectError", payload);
                await handleAuthConnection({
                    walletAddress: account.address,
                })(dispatch);

                socket.connect();
            }

            function onDisconnect() {
                console.debug("Connected to websocket", socket.connected);
                setIsSocketConnected(socket.connected);
            }

            async function onError(payload) {
                if (`${payload}` !== "[object Object]") {
                    if (
                        [
                            "Privy access token not provided",
                            "Bad privy access token",
                        ].includes(payload)
                    ) {
                        socket.disconnect();
                        // we get a new access token if possible
                        await getPrivyAccessTokenOrLogout(
                            "socket onError",
                            true,
                        );
                        socket.connect();
                    } else {
                        console.error(payload);
                        dispatch(
                            setSnackbarFeedback({
                                type: "error",
                                message: payload,
                            }),
                        );
                    }
                } else {
                    console.error(payload);
                }
            }
            socket.on("connect", onConnect);
            socket.on("connectError", onConnectError);
            socket.on("disconnect", onDisconnect);
            socket.on("error", onError);
            socket.connect();

            return () => {
                socket.disconnect();

                socket.off("connect", onConnect);
                socket.off("connectError", onConnectError);
                socket.off("disconnect", onDisconnect);
                socket.off("error", onError);

                socketConnectionRef.current = false;
            };
        }
    }, [
        account?.address,
        privyUserIsFullyAuthenticated,
        dispatch,
        isConnected,
    ]);

    // Initializing contracts
    const contractsSettings = useSelector(selectContractSettings);
    useEffect(() => {
        if (murmurContract && !contractsSettings.loaded) {
            dispatch(getContractSettings(murmurContract));
        }
    }, [contractsSettings, dispatch, murmurContract]);

    const epochSettings = useSelector(selectEpochSettings);
    useEffect(() => {
        if (murmurContract && !epochSettings.loaded) {
            dispatch(getEpochSettingsFromContract(murmurContract));
        }
    }, [epochSettings, dispatch, murmurContract]);

    if (noConnection) {
        return (
            <FullscreenMessage
                icon={<WifiOffIcon sx={{ fontSize: 50 }} />}
                label={
                    <Typography sx={{ textAlign: "center" }}>
                        No internet connection.
                        <br />
                        Please check your connection and{" "}
                        <Typography
                            color="secondary"
                            component="span"
                            sx={{ textDecoration: "underline" }}
                            onClick={() => window.location.reload()}
                        >
                            try again
                        </Typography>
                        .
                    </Typography>
                }
            />
        );
    }

    if (contractsError || backendCheckError) {
        return (
            <FullscreenMessage
                icon={<WifiOffIcon sx={{ fontSize: 50 }} />}
                label={
                    <Typography whiteSpace="pre-line">
                        {contractsError || backendCheckError}
                    </Typography>
                }
            />
        );
    }

    if (
        location.pathname !== ROUTES.START &&
        (backendCheckLoading || !murmurContract)
    ) {
        return (
            <FullscreenCircularProgress label={"Building your experience..."} />
        );
    }

    const generateRoutePageWithPageContainer = ({
        PageComponent,
        isProtected,
        pageContainerProps = {
            showHeader: true,
            showNav: isMultiTopicsTabsEnabled && !isMobile,
        },
        suspenseFallback = (
            <FullscreenCircularProgress onlyLoader sx={{ height: "100%" }} />
        ),
    }) => {
        return (
            <PageProtectionWrapper isProtected={isProtected}>
                <PageContainer {...pageContainerProps}>
                    <React.Suspense fallback={suspenseFallback}>
                        <PageComponent />
                    </React.Suspense>
                </PageContainer>
            </PageProtectionWrapper>
        );
    };

    return (
        <AppContextsProvider
            isSocketConnected={isSocketConnected}
            contracts={contracts}
        >
            <Routes>
                <Route
                    path={ROUTES.START}
                    element={
                        <React.Suspense
                            fallback={<FullscreenCircularProgress />}
                        >
                            <StartPage
                                deferredPrompt={deferredPrompt}
                                setDeferredPrompt={setDeferredPrompt}
                            />
                        </React.Suspense>
                    }
                />
                <Route
                    path={ROUTES.PRIVACY_POLICY}
                    element={<PrivacyPolicyPage />}
                />
                <Route
                    path={ROUTES.MESSAGE(":topicId", ":messageIdentifier")}
                    element={generateRoutePageWithPageContainer({
                        PageComponent: PostPage,
                        isProtected: false,
                    })}
                />
                <Route
                    path={ROUTES.CREATE_MESSAGE(":topicId")}
                    element={generateRoutePageWithPageContainer({
                        PageComponent: CreatePostPage,
                        isProtected: true,
                    })}
                />

                <Route
                    path={ROUTES.PREVIEW_MESSAGE(":topicId")}
                    element={generateRoutePageWithPageContainer({
                        PageComponent: PreviewPostPage,
                        isProtected: true,
                    })}
                />

                <Route
                    element={generateRoutePageWithPageContainer({
                        PageComponent: Outlet,
                        isProtected: true,
                        pageContainerProps: {
                            showHeader: true,
                            showNav: isMultiTopicsTabsEnabled,
                        },
                    })}
                >
                    <Route path={ROUTES.HOME} element={<TopicsPage />} />
                    {import.meta.env.VITE_ENABLED_MULTI_TOPICS_TABS ===
                        "true" && (
                        <Route path={ROUTES.FEEDS} element={<ExplorePage />} />
                    )}
                    {import.meta.env.VITE_ENABLED_MULTI_TOPICS_TABS ===
                        "true" && (
                        <Route
                            path={ROUTES.SUBSCRIPTIONS}
                            element={<MySubscriptionsPage />}
                        />
                    )}
                    <Route
                        path={ROUTES.FEED(":topicId")}
                        element={<FeedDetailsPage />}
                    />

                    <Route
                        path={ROUTES.CHANGE_SPOT(":topicId")}
                        element={<FeedChangeSpotPage />}
                    />

                    <Route
                        path={ROUTES.JOIN(":topicId")}
                        element={<FeedJoinPage />}
                    />

                    <Route
                        path={ROUTES.FEED_SUBSCRIBERS(":topicId")}
                        element={<FeedSubscribersPage />}
                    />
                    <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
                    <Route
                        path={ROUTES.FEED_NOTIFICATIONS(":topicId")}
                        element={<FeedNotificationsPage />}
                    />
                    <Route
                        path={ROUTES.FEED_ABOUT(":topicId")}
                        element={<FeedAboutPage />}
                    />
                </Route>
                <Route
                    path="*"
                    element={
                        <React.Suspense
                            fallback={<FullscreenCircularProgress onlyLoader />}
                        >
                            <NotFoundPage />
                        </React.Suspense>
                    }
                />
            </Routes>

            <ConnectedSnackbar />
            <NoFundsModal />
        </AppContextsProvider>
    );
};

export default App;
