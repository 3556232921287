import { createContext } from "react";

export const ContractsContext = createContext({});

export const MESSAGE_VISIBILITY = {
    SECRET: "secret",
    PUBLIC: "public",
};

export const MessageStatusContext = createContext({
    messageVisibility: MESSAGE_VISIBILITY.SECRET,
    // This is a placeholder to show the shape of the context value,
    // the real value is defined in AppContextsProvider
    setMessageStatus: (newContextValue) => {},
});

export const SocketConnectedContext = createContext(false);

export const MessagePaymentPendingContext = createContext({
    setIsPendingPayment: () => console.error("UNIMPLEMENTED"),
    isPendingPayment: () => false,
});

export const TLPWorkersContext = createContext({
    // This is a placeholder to show the shape of the context value,
    // the real value is defined in AppContextsProvider
    tlpsStatus: {},
    startTLP: async ({ messageIdentifier, tlpData, onReveal, onError }) => {},
    pauseTLP: (messageIdentifier) => {},
    cancelTLP: (messageIdentifier) => {},
    getTLPStatus: (messageIdentifier) => {},
});

export const MessageRevealedContext = createContext({
    // This is a placeholder to show the shape of the context value,
    // the real value is defined in AppContextsProvider
    revealedMessage: null,
    setRevealedMessage: () => {},
});
export const TooLateForMessageRevealContext = createContext({
    // This is a placeholder to show the shape of the context value,
    // the real value is defined in AppContextsProvider
    tooLateForMessageReveal: false,
    setTooLateForMessageReveal: () => {},
});

export const JustSubscribedContext = createContext({
    // This is a placeholder to show the shape of the context value,
    // the real value is defined in AppContextsProvider
    subscribed: true,
    setSubscribed: () => {},
});

export const DraftMessageContext = createContext({
    // This is a placeholder to show the shape of the context value,
    // the real value is defined in AppContextsProvider
    draftMessage: {},
    updateDraftMessage: async (draft) => {},
});

export const NoFundsModalContext = createContext({
    // This is a placeholder to show the shape of the context value,
    // the real value is defined in AppContextsProvider
    noFundsModalOpen: false,
    setIsNoFundsModalOpen: () => {},
    triggerOnlyIfFunds: (onFundsAvailableCallback) => {},
    storedOnFundedCallback: () => {}, // callback to be triggered once the wallet is funded
});

export const ChangeSpotLoadingContext = createContext({
    // This is a placeholder to show the shape of the context value,
    // the real value is defined in AppContextsProvider
    changeSpotIsLoading: false,
    setChangeSpotIsLoading: () => {},
});
