import {
    Button,
    CircularProgress,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { selectIsConnected, selectIsLoadingAuth } from "src/slices/authSlice";
import { selectCurrentUserProfile } from "src/slices/profileSlice";

import AvatarWithBadge from "src/components/AvatarWithBadge";

import { ROUTES } from "src/constants";

export default function CurrentUserAvatar({
    profile,
    size = 25,
    noLink = false,
}) {
    const navigate = useNavigate();
    const theme = useTheme();

    const { pathname } = useLocation();
    const profileFromStore = useSelector(selectCurrentUserProfile);

    const isConnected = useSelector(selectIsConnected);
    const isLoading = useSelector(selectIsLoadingAuth);

    const isMobile = useMediaQuery("(max-width:900px)");

    const profileToDisplay = profile ?? profileFromStore;
    const displayUnauthenticatedAvatar = !isLoading && !isConnected;
    const displayAvatar = !isLoading && isConnected;

    const avatarContent = (
        <>
            {isLoading && <CircularProgress size={size} />}
            {displayAvatar && (
                <AvatarWithBadge profile={profileToDisplay} avatarSize={size} />
            )}
            {displayUnauthenticatedAvatar && (
                <AvatarWithBadge
                    avatarSize={size}
                    onClick={() => navigate(ROUTES.START)}
                />
            )}
        </>
    );

    return import.meta.env.VITE_ENABLED_MULTI_TOPICS_TABS !== "true" ||
        noLink ? (
        avatarContent
    ) : (
        <Button
            onClick={() =>
                navigate(
                    displayUnauthenticatedAvatar
                        ? ROUTES.START
                        : ROUTES.PROFILE,
                )
            }
            sx={{
                flex: 1,
                padding: 0,
                backgroundColor: displayUnauthenticatedAvatar
                    ? theme.palette.primary.main
                    : undefined,
            }}
        >
            <MenuItem
                sx={{
                    width: "100%",
                    height: "100%",
                    justifyContent: isMobile ? "center" : "flex-start",
                }}
                selected={pathname === ROUTES.PROFILE}
            >
                {avatarContent}
                {!isMobile && (
                    <Typography sx={{ padding: 2 }} color="white.main">
                        Profile
                    </Typography>
                )}
            </MenuItem>
        </Button>
    );
}
