import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { FEED_TABS } from "src/constants";

export default function FeedTabs({ topicId }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const tabs = FEED_TABS(topicId);
    const activeTabEntry = Object.entries(tabs).find(
        ([key, { path, activePaths }]) =>
            pathname === path || activePaths.includes(pathname),
    );
    let activeTab = Object.keys(tabs)[0];
    if (activeTabEntry) {
        [activeTab] = activeTabEntry;
    }

    return (
        <TabContext value={activeTab}>
            <TabList
                variant="scrollable"
                allowScrollButtonsMobile
                sx={{
                    backgroundColor: "background.main",
                    ".MuiTabs-flexContainer": { gap: "5px" },
                    ".MuiTabs-scrollButtons.Mui-disabled": { display: "none" },
                }}
            >
                {Object.entries(tabs).map(([key, { label, path }]) => (
                    <Tab
                        key={key}
                        label={label}
                        value={key}
                        onClick={() => navigate(path)}
                    />
                ))}
            </TabList>
        </TabContext>
    );
}
