import { useTheme } from "@emotion/react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useSelector } from "react-redux";

import {
    declineLotteryPrize,
    getCurrentUserLotteryPrizeToRedeem,
    sendSocialNetworkLinkToRedeemPrize,
} from "src/services/api";
import { selectLatestLottery } from "src/slices/lotteriesSlice";

import CurrencyDisplay from "src/components/CurrencyDisplay";
import LotteryBaseModalContent from "src/components/LotteryBaseModalContent";
import ShareOnSocialNetworkButton from "src/components/ShareOnSocialNetworkButton";
import StyledModal from "src/components/StyledModal";

import {
    CURRENCY_SYMBOL,
    MAX_LOTTERY_PRIZE_DECIMALS,
    SOCIAL_NETWORK_SHARE_MESSAGES,
} from "src/constants";
import { convertToCurrency } from "src/utils";

const STEPS = {
    CONGRATS: 1,
    CLAIM: 2,
    REWARD_ON_THE_WAY: 3,
};

export default function LotteryWinnerModal() {
    const [prizeToRedeem, setPrizeToRedeem] = useState();
    const [socialNetworkLink, setSocialNetworkLink] = useState("");
    const [step, setStep] = useState(STEPS.CONGRATS);
    const theme = useTheme();
    const latestLottery = useSelector(selectLatestLottery());

    useEffect(() => {
        const getPrizeToRedeem = async () => {
            const prize = await getCurrentUserLotteryPrizeToRedeem();
            setPrizeToRedeem(prize);
        };
        getPrizeToRedeem();
    }, [latestLottery?.hasBeenHandled, latestLottery?.endDate]);

    const sendSocialNetworkLink = async () => {
        await sendSocialNetworkLinkToRedeemPrize(
            prizeToRedeem.id,
            socialNetworkLink,
        );
    };
    const modalIsOpen = Boolean(prizeToRedeem?.prizeAmountWei > 0);

    const content = {
        [STEPS.CONGRATS]: (
            <LotteryBaseModalContent
                title="Congrats!"
                buttonContent="Claim my reward"
                subtitle={
                    <Typography color="secondary">
                        You are among the winners of the lottery.
                        <br />
                        <CurrencyDisplay
                            amount={prizeToRedeem?.prizeAmountWei}
                            convertFrom={CURRENCY_SYMBOL.WEI}
                            currency={CURRENCY_SYMBOL.ETH}
                            toFixed={MAX_LOTTERY_PRIZE_DECIMALS}
                        />
                        &nbsp;is waiting to be claimed.
                    </Typography>
                }
                onButtonClick={() => setStep(STEPS.CLAIM)}
                buttonProps={{ sx: { mt: 0 } }}
                stackProps={{ gap: 2 }}
            >
                <Typography
                    id="lottery-winner-description-part-1"
                    color="secondary"
                ></Typography>
            </LotteryBaseModalContent>
        ),
        [STEPS.CLAIM]: (
            <LotteryBaseModalContent
                title="Share to claim your reward"
                skipButtonContent="Decline prize"
                onSkipButtonClick={async () => {
                    const lotteryPrizeUpdated = await declineLotteryPrize(
                        prizeToRedeem.id,
                    );
                    if (lotteryPrizeUpdated.declined) {
                        setPrizeToRedeem(undefined);
                        setStep(STEPS.CONGRATS);
                    }
                }}
                stackProps={{ gap: 3 }}
            >
                <Stack gap={1}>
                    <Typography
                        id="lottery-winner-description-part-3"
                        color="secondary"
                        textAlign="left"
                    >
                        1. Click on the share button
                    </Typography>
                    <ShareOnSocialNetworkButton
                        messageFunction={
                            SOCIAL_NETWORK_SHARE_MESSAGES.LOTTERY_WINNER
                        }
                        messageExtraParams={{
                            prizeETH:
                                prizeToRedeem?.prizeAmountWei &&
                                convertToCurrency(
                                    prizeToRedeem?.prizeAmountWei,
                                    CURRENCY_SYMBOL.WEI,
                                    CURRENCY_SYMBOL.ETH,
                                ),
                        }}
                    />
                </Stack>
                <Stack gap={1}>
                    <Typography
                        id="lottery-winner-description-part-4"
                        color="secondary"
                        textAlign="left"
                    >
                        2. Paste the tweet URL here
                    </Typography>
                    <TextField
                        fullWidth
                        onChange={(e) => setSocialNetworkLink(e.target.value)}
                    />
                </Stack>
                <Stack gap={1}>
                    <Typography
                        id="lottery-winner-description-part-5"
                        color="secondary"
                        textAlign="left"
                    >
                        3. Submit to our team for validation
                    </Typography>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            sendSocialNetworkLink();
                            setStep(STEPS.REWARD_ON_THE_WAY);
                        }}
                        disabled={socialNetworkLink.length === 0}
                    >
                        Claim my reward
                    </Button>
                </Stack>
            </LotteryBaseModalContent>
        ),
        [STEPS.REWARD_ON_THE_WAY]: (
            <LotteryBaseModalContent
                title="Your reward is on your way"
                subtitle={
                    <Typography color="secondary">
                        Your reward of{" "}
                        <CurrencyDisplay
                            amount={prizeToRedeem?.prizeAmountWei}
                            convertFrom={CURRENCY_SYMBOL.WEI}
                            currency={CURRENCY_SYMBOL.ETH}
                            toFixed={MAX_LOTTERY_PRIZE_DECIMALS}
                        />{" "}
                        will be sent to your Base wallet as soon as we have
                        validated your publication.
                    </Typography>
                }
                buttonContent="Got it"
                onButtonClick={() => {
                    setPrizeToRedeem(undefined);
                    setStep(STEPS.CONGRATS);
                }}
                buttonProps={{ sx: { mt: 0 } }}
                stackProps={{ gap: 3 }}
            >
                <Typography
                    id="lottery-winner-description-part-6"
                    color="secondary"
                ></Typography>
            </LotteryBaseModalContent>
        ),
    };

    return (
        <>
            <StyledModal
                open={modalIsOpen}
                aria-labelledby="lottery-winner-label"
                aria-describedby="lottery-winner-description"
            >
                <Stack gap={1}>{content[step]}</Stack>
            </StyledModal>
            {modalIsOpen && (
                <Confetti
                    colors={[
                        theme.palette.primary.main,
                        theme.palette.primary.light,
                        theme.palette.yellow.main,
                        theme.palette.darkHeader.main,
                    ]}
                />
            )}
        </>
    );
}
