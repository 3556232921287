import { Box, Button, Slider, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useIsLowBalance from "src/hooks/useIsLowBalance";
import {
    joinFeedFromContract,
    setBurningRateInContract,
} from "src/services/murmur";
import { selectCurrentUserWalletAddress } from "src/slices/authSlice";
import { selectContractSettings } from "src/slices/contractSettingsSlice";
import { setSnackbarFeedback } from "src/slices/snackbarFeedbackSlice";
import {
    getCurrentUserSubscription,
    getFeedSubscriptions,
} from "src/slices/subscriptionsSlice";

import CurrencyDisplay from "src/components/CurrencyDisplay";
import CustomLoadingButton from "src/components/CustomLoadingButton";
import MaxBidAndCurrentEffectivePrice from "src/components/MaxBidAndCurrentEffectivePrice";
import TipMessage from "src/components/TipMessage";

import { CURRENCY_SYMBOL } from "src/constants";
import {
    ChangeSpotLoadingContext,
    ContractsContext,
    JustSubscribedContext,
} from "src/contexts";
import store from "src/store";
import {
    convertCryptoValueToNumber,
    convertToCurrency,
    waitFor,
} from "src/utils";

export default function ChangeSpotForm({
    topicId,
    currentUserIsSubscriber = false,
    burningRateStepInETH,
    currentBurningRateInETH,
    minETHValue = burningRateStepInETH,
    maxETHValue,
    onSubmit,
    onCancel,
    generateKeyPairIfNeeded,
    shouldShowSliderAndPrices = true,
}) {
    const { murmurContract } = useContext(ContractsContext);
    const { setSubscribed } = useContext(JustSubscribedContext);
    const { changeSpotIsLoading, setChangeSpotIsLoading } = useContext(
        ChangeSpotLoadingContext,
    );
    const dispatch = useDispatch();

    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );

    const { isLowBalance } = useIsLowBalance(!currentUserIsSubscriber);
    const [newBurningRate, setNewBurningRate] = useState(
        Number(currentBurningRateInETH) ||
            Number(burningRateStepInETH) ||
            0.00001,
    );

    const handleBurningRateChangeSubmit = async () => {
        let burningRateInWei;
        try {
            setChangeSpotIsLoading(true);

            burningRateInWei = convertToCurrency(
                newBurningRate,
                CURRENCY_SYMBOL.ETH,
                CURRENCY_SYMBOL.WEI,
            );

            if (!burningRateInWei) {
                throw new Error("Failed to convert ETH to Wei");
            }

            let shouldCongratsSubscription = false;
            if (currentUserIsSubscriber) {
                await setBurningRateInContract(
                    murmurContract,
                    topicId,
                    burningRateInWei,
                );
            } else {
                if (generateKeyPairIfNeeded) {
                    await generateKeyPairIfNeeded();
                }
                await joinFeedFromContract(
                    murmurContract,
                    topicId,
                    burningRateInWei,
                );

                shouldCongratsSubscription = true;
            }

            await getCurrentUserSubscription(murmurContract, topicId)(
                dispatch,
                store.getState,
            );

            dispatch(
                setSnackbarFeedback({
                    type: "success",
                    message: currentUserIsSubscriber
                        ? "Max bid per post successfully changed!"
                        : "You joined this topic successfully",
                }),
            );

            // Check backend until cache has been updated
            await waitFor(2_000, async () => {
                const subs = await getFeedSubscriptions(topicId)(dispatch);
                const foundUpdatedSub = subs.find(
                    ({ burningRate, subscriber }) =>
                        Number(burningRate) === Number(burningRateInWei) &&
                        subscriber === currentUserWalletAddress,
                );

                return foundUpdatedSub;
            });

            if (onSubmit) {
                onSubmit();
            }

            if (shouldCongratsSubscription) {
                // Show congrats modal
                setSubscribed(true);
            }
        } catch (e) {
            if (e.decodedError || e.fullError) {
                console.warn(e.decodedError ?? e.fullError);
            }
            console.error(e);
            let errorToDisplay = e.message || e.decodedError || e;
            if (`${e}`.includes("BurningRateAlreadyTaken")) {
                errorToDisplay =
                    "This burning rate is already taken, please choose another one";
            } else if (`${e}`.includes("TransactionExecutionError")) {
                errorToDisplay = "Unexpected error, please try again";
            }
            // Check backend until cache has been updated with subscriber that has choosen same burning rate
            const startOfWait = new Date();
            await waitFor(2_000, async () => {
                const subs = await getFeedSubscriptions(topicId)(dispatch);
                const foundUpdatedSub = subs.find(
                    ({ burningRate }) =>
                        Number(burningRate) === Number(burningRateInWei),
                );
                if (new Date() - startOfWait > 30 * 1_000) {
                    // stop the loading and dispatch the error in case no other subscriber with same burning rate
                    return true;
                }
                return foundUpdatedSub;
            });

            dispatch(
                setSnackbarFeedback({ type: "error", message: errorToDisplay }),
            );
        } finally {
            setChangeSpotIsLoading(false);
        }
    };

    useEffect(() => {
        setNewBurningRate(
            convertToCurrency(
                minETHValue,
                CURRENCY_SYMBOL.WEI,
                CURRENCY_SYMBOL.ETH,
            ),
        );
    }, [minETHValue]);

    useEffect(() => {
        if (currentBurningRateInETH) {
            setNewBurningRate(Number(currentBurningRateInETH));
        }
    }, [currentBurningRateInETH]);

    const handleSliderChange = (event, newValue) => {
        setNewBurningRate(newValue);
    };

    const showSlider =
        minETHValue &&
        minETHValue !== maxETHValue &&
        Number(burningRateStepInETH) > 0 &&
        shouldShowSliderAndPrices;
    const errorMessage = isLowBalance
        ? "Your balance is very low, please add funds to be able to join the queue"
        : undefined;

    return (
        <Stack gap={2}>
            {showSlider && (
                <Box sx={{ pl: 1 }}>
                    <TipMessage
                        id="burning-rate-slider-label"
                        message="Move the slider to the right to increase your lead over other queue members"
                    />
                    <Slider
                        value={
                            typeof newBurningRate === "number"
                                ? newBurningRate
                                : 0
                        }
                        onChange={handleSliderChange}
                        aria-labelledby="burning-rate-slider-label"
                        min={convertCryptoValueToNumber(
                            minETHValue,
                            CURRENCY_SYMBOL.WEI,
                            CURRENCY_SYMBOL.ETH,
                        )}
                        max={convertCryptoValueToNumber(
                            maxETHValue,
                            CURRENCY_SYMBOL.WEI,
                            CURRENCY_SYMBOL.ETH,
                        )}
                        step={Number(burningRateStepInETH)}
                        marks
                        valueLabelDisplay="auto"
                        disabled={changeSpotIsLoading}
                    />
                </Box>
            )}
            <MaxBidAndCurrentEffectivePrice
                burningRateInWei={convertToCurrency(
                    newBurningRate,
                    CURRENCY_SYMBOL.ETH,
                    CURRENCY_SYMBOL.WEI,
                )}
                topicId={topicId}
            />
            <CustomLoadingButton
                onClick={handleBurningRateChangeSubmit}
                loading={changeSpotIsLoading}
                disabled={!!errorMessage}
            >
                {!currentUserIsSubscriber
                    ? "Confirm subscription"
                    : "Confirm the change"}
            </CustomLoadingButton>
            {errorMessage && (
                <Typography color="red">{errorMessage}</Typography>
            )}
            {onCancel && (
                <Button
                    disabled={changeSpotIsLoading}
                    onClick={() => {
                        onCancel();

                        // Reset to current state
                        setNewBurningRate(Number(currentBurningRateInETH));
                    }}
                    color="error"
                    fullWidth
                >
                    Cancel
                </Button>
            )}
        </Stack>
    );
}
