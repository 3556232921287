import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import CurrentUserAvatar from "src/components/CurrentUserAvatar";

import { LAYOUT, ROUTES } from "src/constants";

const pages = [
    { label: "Home", Icon: HomeIcon, path: ROUTES.HOME },
    { label: "Explore", Icon: SearchIcon, path: ROUTES.FEEDS },
    { label: "Subscriptions", Icon: EmailIcon, path: ROUTES.SUBSCRIPTIONS },
];

export default function NavBar({ isIOS }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const iosHomeIndicatorHeight = !isIOS ? 0 : 34;

    const responsiveStyle = isMobile
        ? {
              height: 60,
              width: "100%",
              bottom: 0,
              top: "auto",
              borderTopStyle: "solid",
              borderTopWidth: 1,
          }
        : {
              height: "100vh",
              width: 240,
              top: 0,
              left: 0,
              borderRightStyle: "solid",
              borderRightWidth: 1,
          };

    return (
        <AppBar
            position="static"
            component="nav"
            color="background"
            sx={{
                height: !isMobile
                    ? "100%"
                    : LAYOUT.NAVBAR.HEIGHT + iosHomeIndicatorHeight,
                borderColor: "background.hairline",
                paddingBottom: `${iosHomeIndicatorHeight}px`,
                ...responsiveStyle,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    ...(isMobile
                        ? {
                              flexGrow: 1,
                              alignSelf: "stretch",
                          }
                        : {
                              flexDirection: "column",
                          }),
                }}
            >
                {pages.map(({ label, Icon, path }) => (
                    <Button
                        key={label}
                        onClick={() => navigate(path)}
                        sx={{
                            flex: 1,
                            padding: 0,
                        }}
                    >
                        <MenuItem
                            sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: isMobile
                                    ? "center"
                                    : "flex-start",
                            }}
                            selected={path === pathname}
                        >
                            <Icon
                                sx={{
                                    color:
                                        path === pathname
                                            ? "white"
                                            : "secondary.main",
                                }}
                            />
                            {!isMobile && (
                                <Typography
                                    sx={{ padding: 2 }}
                                    color="white.main"
                                >
                                    {label}
                                </Typography>
                            )}
                        </MenuItem>
                    </Button>
                ))}
                <CurrentUserAvatar />
            </Box>
        </AppBar>
    );
}
