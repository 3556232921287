import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { selectIsConnected } from "src/slices/authSlice";

import FeedHeaderTitle from "src/components/FeedHeaderTitle";
import FeedSubHeader from "src/components/FeedSubHeader";
import FeedTabs from "src/components/FeedTabs";
import Logo from "src/components/Logo";
import UserBalances from "src/components/UserBalances";

import { ROUTES } from "src/constants";
import { MessageStatusContext } from "src/contexts";
import { capitalize } from "src/utils";

export default function Header({ children = null }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { topicId } = useParams();
    const theme = useTheme();
    const body = document.querySelector("body");

    const isConnected = useSelector(selectIsConnected);

    const isPathLengthGreaterThanTwo = location.pathname.split("/").length > 2;
    const displayGoBackButton =
        isPathLengthGreaterThanTwo || location.pathname === ROUTES.PROFILE;

    const {
        messageStatus: { visibility },
    } = useContext(MessageStatusContext);

    let backgroundColor = "background";
    let title = (
        <Box
            sx={{
                alignItems: "flex-start",
                flex: 1,
            }}
        >
            <Button
                onClick={() =>
                    navigate(isConnected ? ROUTES.HOME : ROUTES.START)
                }
                sx={{
                    padding: 0,
                }}
            >
                <Logo />
            </Button>
        </Box>
    );

    let rightItem = (
        <UserBalances
            showCurrentUserAvatar={
                import.meta.env.VITE_ENABLED_MULTI_TOPICS_TABS !== "true"
            }
            onTopic={location.pathname.includes(ROUTES.FEED(topicId))}
        />
    );

    let subTitle = null;
    if (topicId && location.pathname.includes(ROUTES.FEED(""))) {
        backgroundColor = "darkHeader";
        let headerTitleProps = {};
        subTitle = (
            <>
                <FeedSubHeader />
                <FeedTabs topicId={topicId} />
            </>
        );
        if (location.pathname.includes(ROUTES.CREATE_MESSAGE(topicId))) {
            const text = `New post`;
            headerTitleProps = { text };
            subTitle = null;
        } else if (
            location.pathname.includes(ROUTES.PREVIEW_MESSAGE(topicId))
        ) {
            const text = "Secret post";
            headerTitleProps = { text };
            subTitle = null;
        } else if (location.pathname.includes(ROUTES.MESSAGE(topicId))) {
            let text = `${capitalize(visibility)} post`;
            headerTitleProps = { text };
            subTitle = null;
        } else if (location.pathname === ROUTES.CHANGE_SPOT(topicId)) {
            const text = "Choose your spot";
            headerTitleProps = { text };
            subTitle = null;
        } else if (location.pathname === ROUTES.JOIN(topicId)) {
            const text = "Join the queue";
            headerTitleProps = { text };
            subTitle = null;
        }

        title = <FeedHeaderTitle {...headerTitleProps} />;
    } else if (location.pathname === ROUTES.PROFILE) {
        title = null;
    }

    // Status bar color handling on iOS
    if (topicId && location.pathname.includes(ROUTES.FEED(""))) {
        body.setAttribute(
            "style",
            `background-color: ${theme.palette.primary.dark}`,
        );
    } else {
        body.removeAttribute("style");
    }

    let content = children;

    if (!content) {
        content = (
            <>
                <Box
                    flex={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    pt={3}
                    pb={1}
                >
                    {displayGoBackButton && (
                        <Button
                            sx={{
                                minWidth: 0,
                                ml: -1,
                                paddingRight: 0,
                            }}
                            color={"white"}
                            onClick={() => {
                                if (!isConnected) {
                                    // Post detail page is the only one available not connected
                                    navigate(ROUTES.START);
                                } else if (location.state?.from) {
                                    // If on the profile page (from is defined only on profile page), go back to where you came from
                                    // if we know what that is
                                    navigate(location.state.from);
                                } else if (
                                    topicId &&
                                    location.pathname ===
                                        ROUTES.CHANGE_SPOT(topicId)
                                ) {
                                    // From "change spot" page,
                                    // go back to the list of subscribers
                                    navigate(ROUTES.FEED_SUBSCRIBERS(topicId));
                                } else if (
                                    topicId &&
                                    location.pathname.includes(
                                        ROUTES.PREVIEW_MESSAGE(topicId),
                                    )
                                ) {
                                    // From "preview post" page before sending post,
                                    // go back to create post page
                                    navigate(ROUTES.CREATE_MESSAGE(topicId));
                                } else if (
                                    topicId &&
                                    location.pathname !== ROUTES.FEED(topicId)
                                ) {
                                    // If in a topic, in any screen,
                                    // back button should bring you to the feed
                                    navigate(ROUTES.FEED(topicId));
                                } else {
                                    // In doubt, go home
                                    navigate(ROUTES.HOME);
                                }
                            }}
                        >
                            <ArrowBackIosIcon />
                        </Button>
                    )}
                    {title}
                    {rightItem}
                </Box>
                {subTitle}
            </>
        );
    }

    return (
        <AppBar
            position={"relative"}
            component="nav"
            color={backgroundColor}
            style={{
                boxShadow: "none",
            }}
        >
            {content}
        </AppBar>
    );
}
