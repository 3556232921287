import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "src/constants";

export default function SubscribeButton({
    topicId,
    text = "Join the queue now",
    color = "white",
}) {
    const navigate = useNavigate();

    return (
        <Button
            variant="contained"
            onClick={() => navigate(ROUTES.JOIN(topicId))}
            size="small"
            color={color}
            fullWidth
        >
            {text}
        </Button>
    );
}
