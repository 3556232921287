import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useIsLowBalance from "src/hooks/useIsLowBalance";
import useTLPsStatus from "src/hooks/useTLPsStatus";
import { getDraft, saveDraft } from "src/services/draftDB";
import { selectCurrentUserWalletAddress } from "src/slices/authSlice";

import {
    ChangeSpotLoadingContext,
    ContractsContext,
    DraftMessageContext,
    JustSubscribedContext,
    MESSAGE_VISIBILITY,
    MessagePaymentPendingContext,
    MessageRevealedContext,
    MessageStatusContext,
    NoFundsModalContext,
    SocketConnectedContext,
    TLPWorkersContext,
    TooLateForMessageRevealContext,
} from "src/contexts";

export default function AppContextsProvider({
    children,
    isSocketConnected,
    contracts,
}) {
    const { isLowBalance } = useIsLowBalance();
    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );

    const [messageStatus, setMessageStatus] = useState({
        visibility: MESSAGE_VISIBILITY.SECRET,
    });
    const [messagePaymentState, setMessagePaymentState] = useState({});

    const TLPWorkersContextValue = useTLPsStatus(currentUserWalletAddress);

    const [revealedMessage, setRevealedMessage] = useState(null);
    const [tooLateForMessageReveal, setTooLateForMessageReveal] =
        useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [changeSpotIsLoading, setChangeSpotIsLoading] = useState(false);
    const [draftMessage, setDraftMessage] = useState({});
    const updateDraftMessage = useCallback(
        async (draft) => {
            await saveDraft(draft, currentUserWalletAddress);
            setDraftMessage(draft);
        },
        [setDraftMessage, currentUserWalletAddress],
    );

    useEffect(() => {
        (async () => {
            if (currentUserWalletAddress) {
                const storedDraft = await getDraft(currentUserWalletAddress);
                setDraftMessage(storedDraft || {});
            }
        })();
    }, [currentUserWalletAddress]);

    const [noFundsModalOpen, setIsNoFundsModalOpen] = useState(false);
    const [storedOnFundedCallback, setStoredOnFundedCallback] = useState(false);
    const triggerOnlyIfFunds = useCallback(
        (callback) => {
            if (isLowBalance) {
                setIsNoFundsModalOpen(true);
                // see https://dev.to/sarioglu/how-to-store-a-function-using-react-usestate-4ffh for why
                setStoredOnFundedCallback(() => callback);
            } else {
                callback();
            }
        },
        [isLowBalance],
    );
    const onFunded = useCallback(() => {
        if (storedOnFundedCallback) {
            storedOnFundedCallback();
            setIsNoFundsModalOpen(false);
        }
        setStoredOnFundedCallback(null);
    }, [storedOnFundedCallback]);

    useEffect(() => {
        if (!isLowBalance) {
            onFunded();
        }
    }, [isLowBalance, onFunded]);

    return (
        <SocketConnectedContext.Provider value={isSocketConnected}>
            <ContractsContext.Provider value={contracts}>
                <MessageStatusContext.Provider
                    value={{ messageStatus, setMessageStatus }}
                >
                    <MessagePaymentPendingContext.Provider
                        value={{
                            setIsPendingPayment: (
                                messageIdentifier,
                                isPending,
                            ) => {
                                setMessagePaymentState({
                                    ...messagePaymentState,
                                    [messageIdentifier]: isPending,
                                });
                            },
                            isPendingPayment: (messageIdentifier) =>
                                Boolean(messagePaymentState[messageIdentifier]),
                        }}
                    >
                        <TLPWorkersContext.Provider
                            value={TLPWorkersContextValue}
                        >
                            <MessageRevealedContext.Provider
                                value={{
                                    revealedMessage,
                                    setRevealedMessage,
                                }}
                            >
                                <TooLateForMessageRevealContext.Provider
                                    value={{
                                        tooLateForMessageReveal,
                                        setTooLateForMessageReveal,
                                    }}
                                >
                                    <JustSubscribedContext.Provider
                                        value={{
                                            subscribed,
                                            setSubscribed,
                                        }}
                                    >
                                        <DraftMessageContext.Provider
                                            value={{
                                                draftMessage,
                                                updateDraftMessage,
                                            }}
                                        >
                                            <NoFundsModalContext.Provider
                                                value={{
                                                    noFundsModalOpen,
                                                    setIsNoFundsModalOpen: (
                                                        open,
                                                    ) => {
                                                        if (!open) {
                                                            if (isLowBalance) {
                                                                setStoredOnFundedCallback(
                                                                    null,
                                                                );
                                                            } else {
                                                                storedOnFundedCallback();
                                                                setStoredOnFundedCallback(
                                                                    null,
                                                                );
                                                            }
                                                        }
                                                        setIsNoFundsModalOpen(
                                                            open,
                                                        );
                                                    },
                                                    triggerOnlyIfFunds,
                                                    onFunded,
                                                }}
                                            >
                                                <ChangeSpotLoadingContext.Provider
                                                    value={{
                                                        changeSpotIsLoading,
                                                        setChangeSpotIsLoading,
                                                    }}
                                                >
                                                    {children}
                                                </ChangeSpotLoadingContext.Provider>
                                            </NoFundsModalContext.Provider>
                                        </DraftMessageContext.Provider>
                                    </JustSubscribedContext.Provider>
                                </TooLateForMessageRevealContext.Provider>
                            </MessageRevealedContext.Provider>
                        </TLPWorkersContext.Provider>
                    </MessagePaymentPendingContext.Provider>
                </MessageStatusContext.Provider>
            </ContractsContext.Provider>
        </SocketConnectedContext.Provider>
    );
}
