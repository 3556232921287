import { Button, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useNotificationToggle from "src/hooks/useNotificationToggle";

import PushNotificationButton from "src/components/PushNotificationButton";
import StyledModal from "src/components/StyledModal";

import { ROUTES } from "src/constants";
import { TooLateForMessageRevealContext } from "src/contexts";
import { NotificationType } from "src/types/api-interfaces";

export default function TooLateModal() {
    const { topicId } = useParams();
    const navigate = useNavigate();
    const { tooLateForMessageReveal, setTooLateForMessageReveal } = useContext(
        TooLateForMessageRevealContext,
    );
    const { status } = useNotificationToggle({
        notificationType: NotificationType.TLP,
        topicId,
    });

    if (!topicId) {
        return null;
    }

    return (
        <StyledModal
            open={tooLateForMessageReveal}
            onClose={() => {
                setTooLateForMessageReveal(false);
            }}
        >
            <Stack gap={1}>
                <Typography variant="h6">Nice try!</Typography>
                <Typography color="secondary">
                    Unfortunately, someone already revealed the message before
                    you.
                    {!status
                        ? " Do not miss the next one by enabling your notifications!"
                        : ""}
                </Typography>
                {!status ? (
                    <PushNotificationButton
                        notificationType={NotificationType.TLP}
                        topicId={topicId}
                        disabledContent="Notify me next time"
                    />
                ) : (
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            navigate(ROUTES.FEED(topicId));
                            setTooLateForMessageReveal(false);
                        }}
                    >
                        Come back later
                    </Button>
                )}
            </Stack>
        </StyledModal>
    );
}
