import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { selectLatestLottery } from "src/slices/lotteriesSlice";

import LotteryBaseModalContent from "src/components/LotteryBaseModalContent";
import StyledModal from "src/components/StyledModal";

export default function LotteryInfoModal({ open, onClose }) {
    const currentLottery = useSelector(selectLatestLottery());

    return (
        <StyledModal
            open={open}
            aria-labelledby="lottery-info-label"
            aria-describedby="lottery-info-description"
        >
            <LotteryBaseModalContent
                title="How to collect points"
                buttonContent="Got it"
                onButtonClick={onClose}
            >
                <Stack sx={{ mt: 4 }}>
                    <Stack direction="row" alignItems="center" gap={1} py={1}>
                        <Typography color="secondary" fontSize={14}>
                            Reveal a private post to the public
                        </Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            flex={1}
                            spacing={0.5}
                        >
                            <Typography fontSize={14}>
                                {currentLottery?.breakTlpMultiplierFactor}
                            </Typography>
                            <LocalActivityIcon fontSize="14" />
                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack direction="row" alignItems="center" gap={1} py={1}>
                        <Typography color="secondary" fontSize={14}>
                            Pay to open a private post
                        </Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            flex={1}
                            spacing={0.5}
                        >
                            <Typography fontSize={14}>
                                {currentLottery?.paymentMultiplierFactor}{" "}
                            </Typography>
                            <LocalActivityIcon fontSize="14" />
                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack direction="row" alignItems="center" gap={1} py={1}>
                        <Box maxWidth="70%" textAlign="left">
                            <Typography color="secondary" fontSize={14}>
                                Pay to slow down the propagation of a private
                                post
                            </Typography>
                        </Box>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            flex={1}
                            spacing={0.5}
                        >
                            <Typography fontSize={14}>
                                {currentLottery?.slowdownMultiplierFactor}
                            </Typography>
                            <LocalActivityIcon fontSize="14" />
                        </Stack>
                    </Stack>
                    <Divider />
                </Stack>
            </LotteryBaseModalContent>
        </StyledModal>
    );
}
